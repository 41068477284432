import { ClientDayOfWeekType, ClientDocument } from './Client';
import { DDCCode } from './DDC';

export interface DeliveryWindow {
  alternative: boolean;
  deliveryScheduleId: string;
  deliveryWindowId: string;
  endDate: string;
  expirationDate: string;
  startDate: string;
  updatedAt: string;
  vendorId: string;
}

export interface GenerateDeliveryWindowsParams {
  ddcCode: DDCCode;
  document?: ClientDocument;
}

export interface ResendDeliveryWindowsPayload {
  documents: ClientDocument[];
}

export enum DeliveryWindowsModalTab {
  active = 'active',
  deleted = 'deleted',
}

export enum DeliveryWindowOrigin {
  daysOfWeek = 'DayOfWeek',
  inclusionRule = 'Rule',
  exclusionRule = 'RuleExclusion',
  autoblock = 'Autoblock',
  holiday = 'Holiday',
  removedDayOfWeek = 'RemovedDayOfWeek',
  replacedDueHoliday = 'ReplacedDueHoliday',
  holidayDeleted = 'HolidayDeleted',
  ruleDeleted = 'RuleDeleted',
  ddcDisabled = 'DdcDisabled',
  outOfWindowRange = 'OutOfWindowRange',
  integrationIssue = 'IntegrationIssue',
  outOfHolidayCalculation = 'OutOfHolidayCalculation',
  autoblockStrategyRemoved = 'AutoblockStrategyRemoved',
  autoblockDeleted = 'AutoblockDeleted',
  capacityStrategy = 'CapacityStrategy',
  deliveryScheduledIdUpdated = 'DeliveryScheduledIdUpdated',
  deliveryFrequencyChanged = 'DeliveryFrequencyChanged',
  visitDateChanged = 'VisitDateChanged',
  expiredDeliveryWindow = 'ExpiredDeliveryWindow',
  unknown = 'Unknown',
}

export interface CompleteDeliveryWindow {
  id: string;
  deliveryDate: string;
  deliveryType: ClientDayOfWeekType;
  minValue?: number;
  addAmount?: number;
  expirationDate: string;
  lastUpdateDate: string;
  origin: DeliveryWindowOrigin;
  minValueDivergence?: number;
  addAmountDivergence?: number;
  expirationDateDivergence?: string;
  hasAutoblockChange?: boolean;
}

export enum DeliveryWindowsModalError {
  accountIdNotFound = 'accountIdNotFound',
}

export interface CompleteDeliveryWindowAPIResponse {
  [DeliveryWindowsModalTab.active]: CompleteDeliveryWindow[];
  [DeliveryWindowsModalTab.deleted]: CompleteDeliveryWindow[];
  error?: DeliveryWindowsModalError;
}

export interface CompleteDeliveryWindowList extends CompleteDeliveryWindowAPIResponse {
  name: string;
  country: string;
}
