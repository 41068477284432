import { ROUTES } from 'constants/Routes.constants';
import { formatBaseURL } from 'utils/formatBaseURL/formatBaseURL';

export enum TrackingAutoblockPageNames {
  list = 'Availability Parameters List',
  progress = 'Availability Parameters Progress',
  create = 'Availability Parameters Create',
  edit = 'Availability Parameters Edit',
}

export enum TrackingClientPageNames {
  list = 'Clients List',
  edit = 'Clients Edit',
  rules = 'Clients Rules',
}

export enum TrackingDDCPageNames {
  list = 'DDCs List',
  edit = 'DDCs Edit',
  rules = 'DDCs Rules',
  holidays = 'DDCs Holidays',
}

export enum TrackingImportsAndExportsPageNames {
  clientUpload = 'Clients Upload',
  clientDownload = 'Clients Download',
  ruleUpload = 'Rules Upload',
  ruleDownload = 'Rules Download',
}

export const TrackingPageNames: Record<string, string> = {
  [formatBaseURL('DDCs')]: TrackingDDCPageNames.list,
  [formatBaseURL('DDCs/Edit')]: TrackingDDCPageNames.edit,
  [formatBaseURL('DDCs/Holiday')]: TrackingDDCPageNames.holidays,
  [formatBaseURL('DDCs/DDCsRules')]: TrackingDDCPageNames.rules,
  [formatBaseURL('DeliveryWindow')]: TrackingClientPageNames.list,
  [formatBaseURL('DeliveryWindow/ClientEdit')]: TrackingClientPageNames.edit,
  [ROUTES.AUTOBLOCK_CREATE]: TrackingAutoblockPageNames.create,
  [ROUTES.AUTOBLOCK_EDIT]: TrackingAutoblockPageNames.edit,
  [ROUTES.AUTOBLOCK_LIST]: TrackingAutoblockPageNames.list,
  [ROUTES.AUTOBLOCK_PROGRESS]: TrackingAutoblockPageNames.progress,
  [formatBaseURL('DeliveryWindow/ClientRules')]: TrackingClientPageNames.rules,
  [formatBaseURL('ImportsAndExports/UploadClientsBase')]:
    TrackingImportsAndExportsPageNames.clientUpload,
  [formatBaseURL('ImportsAndExports/DownloadClientsBase')]:
    TrackingImportsAndExportsPageNames.clientDownload,
  [formatBaseURL('ImportsAndExports/UploadRulesBase')]:
    TrackingImportsAndExportsPageNames.ruleUpload,
  [formatBaseURL('ImportsAndExports/DownloadRulesBase')]:
    TrackingImportsAndExportsPageNames.ruleDownload,
};
