import { useLogService } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { isLocalHost } from 'utils/envUtils';
import startServer from '../component-test/server';
import App from './App';

if (isLocalHost()) {
  startServer().logging = true;
}

const reactLifecycles = singleSpaReact({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLogService().error(error);
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
