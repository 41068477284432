import { createGenerateClassName, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import {
  createFeatureToggleServiceV2,
  generateIncrementalCounter,
  usePreferredLanguageV2,
} from 'admin-portal-shared-services';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';
import useSegmentAnalytics from 'hooks/useSegmentAnalytics/useSegmentAnalytics';
import { I18nLanguageProvider } from 'i18n/LanguageProvider';
import React from 'react';
import { EnvProps } from 'types/EnvProps';
import { defaultTheme, GlobalStyles } from './config/theme';
import AppBarConfigProvider from './providers/AppBarConfigProvider/AppBarConfigProvider';

function App({ segmentKey, optimizelyKey }: EnvProps): JSX.Element {
  const styleKey = 'admin-portal-delivery-window-beta-mfe';
  const counterGenerator = generateIncrementalCounter(styleKey);
  const generateClassName = createGenerateClassName({
    productionPrefix: styleKey,
    seed: styleKey,
    customGetNextCounterId: counterGenerator,
  });

  useSegmentAnalytics(segmentKey);

  createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, optimizelyKey);

  const { preferredLanguage } = usePreferredLanguageV2();

  const appBar = React.useMemo(() => {
    return <AppBarConfigProvider />;
  }, [preferredLanguage]);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <StylesProvider generateClassName={generateClassName}>
        <I18nLanguageProvider>{appBar}</I18nLanguageProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
}

export default App;
