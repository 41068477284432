import { ClientFileStatus, ClientFileType, ClientUploadFile } from 'domains/ClientFile';

export const UploadedQueuedFileMock: ClientUploadFile = {
  fileId: 'fileID1',
  errorFileId: 'errorFileID',
  name: 'uploaded-file-01.csv',
  createdAt: '2021-12-02T12:00:00Z',
  processingStartDate: '2021-12-14T12:00:00Z',
  endProcessingDate: null,
  linesQuantity: null,
  successLinesQuantity: null,
  errorLinesQuantity: null,
  status: ClientFileStatus.queued,
  email: 'user@email.com',
  type: ClientFileType.uploadClientsExceptionsFile,
};

export const UploadedProcessingFileMock: ClientUploadFile = {
  fileId: 'fileID2',
  errorFileId: 'errorFileID',
  name: 'uploaded-file-02.csv',
  createdAt: '2021-12-02T12:00:00Z',
  processingStartDate: '2021-12-04T12:00:00Z',
  endProcessingDate: null,
  linesQuantity: 1000,
  successLinesQuantity: null,
  errorLinesQuantity: null,
  status: ClientFileStatus.processing,
  email: 'user-2@email.com',
  type: ClientFileType.uploadClientsExceptionsFile,
};

export const UploadedProcessedWithErrorsFileMock: ClientUploadFile = {
  fileId: 'fileID3',
  errorFileId: 'errorFileID',
  name: 'uploaded-file-03.csv',
  createdAt: '2021-12-02T12:00:00Z',
  processingStartDate: '2021-12-02T12:00:00Z',
  endProcessingDate: '2022-01-02T12:00:00Z',
  linesQuantity: 1000,
  successLinesQuantity: 990,
  errorLinesQuantity: 10,
  status: ClientFileStatus.processed,
  email: 'user-2@email.com',
  type: ClientFileType.uploadClientsExceptionsFile,
};

export const UploadedProcessedWithoutErrorsFileMock: ClientUploadFile = {
  fileId: 'fileID4',
  errorFileId: 'errorFileID',
  name: 'uploaded-file-04.csv',
  createdAt: '2021-12-02T12:00:00Z',
  processingStartDate: '2021-12-01T12:00:00Z',
  endProcessingDate: '2022-01-02T12:00:00Z',
  linesQuantity: 1000,
  successLinesQuantity: 1000,
  errorLinesQuantity: 0,
  status: ClientFileStatus.processed,
  email: 'user-3@email.com',
  type: ClientFileType.uploadClientsExceptionsFile,
};

export const UploadHistoryListMock = [
  UploadedQueuedFileMock,
  UploadedProcessingFileMock,
  UploadedProcessedWithErrorsFileMock,
  UploadedProcessedWithoutErrorsFileMock,
];

export const UploadHistoryListAPIListMock = {
  totalSize: 1000,
  pageSize: 100,
  page: 1,
  data: UploadHistoryListMock,
};

export const FileMock = new Blob(['test'], { type: 'text/csv' });
export const FileURLMock = 'https://people.sc.fsu.edu/~jburkardt/data/csv/addresses.csv';
