import { SidebarItem } from 'admin-portal-shared-services';
import { ROUTER_BASENAME } from 'config/constants';
import { permissionScopes } from 'constants/PermissionScopes.constant';
import { ROUTES } from 'constants/Routes.constants';
import { formatMessage } from 'i18n/formatters';
import { CheckOnePermission, CheckPermission } from 'utils/checkUserScope/checkUserScope';
import {
  AutoblockIcon,
  DDCsListIcon,
  DeliveryWindowIcon,
  ImportsAndExportsIcon,
} from './AppbarConfigProviderIcons';

export const getAppbarItems = (): SidebarItem[] => {
  const items: SidebarItem[] = [];

  if (CheckPermission(permissionScopes.DDC_LIST)) {
    items.push({
      id: 'DDCs_List_menu_item',
      title: formatMessage({ id: 'AppBar.MENU_DDCS_LIST' }),
      icon: DDCsListIcon,
      path: `${ROUTER_BASENAME}/DDCs`,
    });
  }

  if (CheckPermission(permissionScopes.CLIENT_LIST)) {
    items.push({
      id: 'Delivery_Window_menu_item',
      title: formatMessage({ id: 'AppBar.MENU_DELIVERY_WINDOW' }),
      icon: DeliveryWindowIcon,
      path: `${ROUTER_BASENAME}/DeliveryWindow`,
    });
  }

  // temporary validation while there is no rule csv page
  if (
    CheckPermission(permissionScopes.IMPORTS_EXPORTS) &&
    CheckOnePermission([
      permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ,
      permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
    ])
  ) {
    items.push({
      id: 'Imports_Exports_menu_item',
      title: formatMessage({ id: 'AppBar.MENU_IMPORTS_AND_EXPORTS' }),
      icon: ImportsAndExportsIcon,
      path: `${ROUTER_BASENAME}/ImportsAndExports`,
    });
  }

  if (CheckPermission(permissionScopes.DDC_AUTOBLOCK_READ)) {
    items.push({
      id: 'Autoblock_menu_item',
      title: formatMessage({ id: 'AppBar.MENU_AUTOBLOCK' }),
      icon: AutoblockIcon,
      path: ROUTES.AUTOBLOCK,
    });
  }

  return items;
};
