import { Rule, RuleClientType, RuleDTO, RuleType } from 'domains/RulesFormData';
import { ClientMock } from './Client.mock';

export const RuleMock: Rule = {
  id: '3fa85f64-5717-4qwe2-b3fc-1231123afa6',
  ruleType: RuleType.exclusion,
  ddcCode: '321',
  ddcName: 'Nome do CDD 03',
  clientDocument: '15415415480',
  type: RuleClientType.ClientOnly,
  clientName: 'Client Name 4',
  deliveryDate: '2023-01-01T00:00:00Z',
  flexible: false,
  expirationDate: '2023-01-01T00:00:00Z',
  localExpirationDate: '2023-01-01',
  localExpirationTime: '00:00',
  country: 'BR',
};

export const RuleInclusionMock: Rule = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  ruleType: RuleType.inclusion,
  ddcCode: '357',
  ddcName: 'Rio de Janeiro / São Cristóvão',
  clientDocument: '15415415488',
  type: RuleClientType.ClientOnly,
  clientName: 'Client Name 2',
  deliveryDate: '2023-01-01T00:00:00Z',
  flexible: true,
  minValue: 100,
  addAmount: 20,
  expirationDate: '2023-01-01T00:00:00Z',
  localExpirationDate: '2023-01-01',
  localExpirationTime: '00:00',
  country: 'BR',
};

export const RuleExclusionMock: Rule = {
  id: '3fa85f64-5717-4562-b3fc-1231123afa6',
  ruleType: RuleType.exclusion,
  ddcCode: '444',
  ddcName: 'Rio de Janeiro / Nova Iguaçu',
  clientDocument: '15415415480',
  type: RuleClientType.ClientOnly,
  clientName: 'Client Name 4',
  deliveryDate: '2023-01-01T00:00:00Z',
  flexible: true,
  expirationDate: '2023-01-01T00:00:00Z',
  country: 'BR',
};

export const RuleFlexibleMock: Rule = {
  id: '3fa851234-5717-4qwe2-b3fc-1231123afa6',
  ruleType: RuleType.inclusion,
  ddcCode: '1527',
  ddcName: 'Espírito Santo / Vitória',
  clientDocument: '15415415480',
  type: RuleClientType.ClientOnly,
  clientName: 'Client Name 4',
  deliveryDate: '2023-02-01T00:00:00Z',
  flexible: true,
  minValue: 100,
  addAmount: 20,
  expirationDate: '2023-01-01T00:00:00Z',
  localExpirationDate: '2023-01-01',
  localExpirationTime: '00:00',
  country: 'BR',
};

export const RuleFreeMock: Rule = {
  id: '312351234-5717-4qwe2-b3fc-1231123afa6',
  ruleType: RuleType.inclusion,
  ddcCode: '666',
  ddcName: '',
  clientDocument: '15415415480',
  type: RuleClientType.ClientOnly,
  clientName: 'Client Name 4',
  deliveryDate: '2023-04-01T00:00:00Z',
  flexible: false,
  expirationDate: '2023-01-01T00:00:00Z',
  localExpirationDate: '2023-01-01',
  localExpirationTime: '00:00',
  country: 'BR',
};

export const RulesListMock = [
  RuleMock,
  RuleInclusionMock,
  RuleExclusionMock,
  RuleFlexibleMock,
  RuleFreeMock,
];
export enum ruleTypeIndex {
  RuleExclusion = 2,
  RuleFlexible = 3,
  RuleFree = 4,
}

export const RuleDtoListMock: RuleDTO[] = [
  {
    id: ClientMock.id,
    inclusionDate: '2022-12-12T12:00:00Z',
    exclusionDate: '2022-12-12T12:00:00Z',
    ddcCode: ClientMock.ddcCode,
    clientDocument: ClientMock.document,
    expiration: '2022-12-13T12:00:00Z',
    flexible: true,
  },
];
