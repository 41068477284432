import { DDCCode, DDCDaysOfWeek } from './DDC';
import { DDCFilter } from './DDCAutoblock';

export enum AddAutoblockType {
  date = 'date',
  daysOfWeek = 'daysOfWeek',
}

export enum StrategyType {
  TransformToFree = 'TransformToFree',
  TransformToFlex = 'TransformToFlex',
  IncreaseMinValue = 'IncreaseMinValue',
  IncreaseAddAmount = 'IncreaseAddAmount',
  Block = 'Block',
}

export enum DdcGroup {
  All = 'All',
  Free = 'Free',
  Flex = 'Flex',
  NoDelivery = 'NoDelivery',
}

export enum AutoblockChannels {
  BeesCustomerWeb = 'B2B_WEB',
  BeesCustomerApp = 'B2B_APP',
  BeesForce = 'B2B_FORCE',
  BeesGrow = 'CX_TLP',
  BeesLink = 'B2B_LNK',
  NonBees = 'NON-BEES',
  Other = 'OTHER',
}

export interface AddAutoblockStrategyFormData {
  uuid: string;
  percentage: number;
  strategyType: StrategyType;
  group: DdcGroup;
  percentageToIncrease?: number;
}

export interface AddAutoblockFormData {
  id?: string;
  ddcs: DDCFilter[];
  type: AddAutoblockType;
  date: Date;
  daysOfWeek: DDCDaysOfWeek[];
  visitLimit: number;
  channels: AutoblockChannels[];
  strategies: AddAutoblockStrategyFormData[];
}

export interface AddAutoblockStrategySubmitData {
  percentage: number;
  type: StrategyType;
  group: DdcGroup;
  percentageToIncrease?: number;
}

export interface AddAutoblockSubmitData {
  ddcCodes: DDCCode[];
  date?: string;
  daysOfWeek?: DDCDaysOfWeek[];
  visitLimit: number;
  channels: AutoblockChannels[];
  strategies: AddAutoblockStrategySubmitData[];
}

export enum AddAutoblockErrorCodes {
  // Fixed enum value for testing mocks
  mock = 'mock',
  validationFailed = 'ValidationFailed',
  notFound = 'NotFound',
  earlierDate = 'EarlierDate',
  alreadyExists = 'AlreadyExists',
  ddcNotFound = 'DdcNotFound',
  maxStrategiesReached = 'MaxStrategiesReached',
  invalidPercentage = 'InvalidPercentage',
  duplicatedPercentage = 'DuplicatedPercentage',
  mandatoryIncreaseValue = 'MandatoryIncreaseValue',
  invalidIncreaseValue = 'InvalidIncreaseValue',
  invalidSubgroup = 'InvalidSubgroup',
}

export interface AddAutoblockFailure {
  ddcCode: DDCCode;
  errors: { type: AddAutoblockErrorCodes }[];
}

export interface AddAutoblockResponseData {
  successes: { ddcCode: DDCCode }[];
  failures: AddAutoblockFailure[];
}
