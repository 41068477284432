import { createEvent, Store } from 'effector';
import _ from 'lodash';

interface PersistConfigBase {
  key?: string;
}

interface PersistConfigWithBlacklist extends PersistConfigBase {
  blackList?: string[];
  whiteList?: never;
}

interface PersistConfigWithWhitelist extends PersistConfigBase {
  blackList?: never;
  whiteList?: string[];
}

type PersistConfig = PersistConfigWithBlacklist | PersistConfigWithWhitelist;

const defaultConfig: PersistConfig = {
  key: 'persist',
};

export const withPersist = <state extends Record<string, any>>(
  store: Store<state>,
  config = defaultConfig
): Store<state> => {
  const persistKey = `${config.key}`;
  const snapshot = localStorage.getItem(persistKey);
  const rehydrate = createEvent('@PERSIST/REHYDRATE');
  if (snapshot) {
    store.on(rehydrate, () => ({ ...store.defaultState, ...JSON.parse(snapshot) }));
    rehydrate();
  }
  store.watch((state) => {
    let stateToPersist: Record<string, any> = state;
    if (config.whiteList) {
      const whiteList = config.whiteList || [];
      stateToPersist = _.pick(state, whiteList);
    } else if (config.blackList) {
      const blackList = config.blackList || [];
      stateToPersist = _.omit(state, blackList);
    }
    localStorage.setItem(persistKey, JSON.stringify(stateToPersist));
  });
  return store;
};
