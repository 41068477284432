import { ClientFile, ClientFileStatus, ClientFileType } from 'domains/ClientFile';

export const DownloadBaseQueuedFileMock: ClientFile = {
  type: ClientFileType.downloadClients,
  fileId: 'fileID1',
  processingStartDate: '2021-12-14T12:00:00Z',
  status: ClientFileStatus.queued,
  email: 'user@email.com',
  name: 'file-name.csv',
};

export const DownloadBaseProcessingFileMock: ClientFile = {
  type: ClientFileType.downloadClients,
  fileId: 'fileID2',
  processingStartDate: '2021-12-04T12:00:00Z',
  status: ClientFileStatus.processing,
  email: 'user-2@email.com',
  name: 'file-name.csv',
};

export const DownloadBaseProcessedFileMock: ClientFile = {
  type: ClientFileType.downloadClients,
  fileId: 'fileID4',
  processingStartDate: '2021-12-01T12:00:00Z',
  status: ClientFileStatus.processed,
  email: 'user-3@email.com',
  name: 'file-name.csv',
};

export const ClientAuditFileMock: ClientFile = {
  type: ClientFileType.downloadClientAudit,
  fileId: 'fileID4',
  processingStartDate: '2021-12-01T12:00:00Z',
  status: ClientFileStatus.processed,
  email: 'user-3@email.com',
  name: 'file-name.csv',
};

export const DownloadBaseListMock: ClientFile[] = [
  DownloadBaseQueuedFileMock,
  DownloadBaseProcessingFileMock,
  DownloadBaseProcessedFileMock,
  ClientAuditFileMock,
];

export const DownloadBaseListAPIListMock = {
  totalSize: 1000,
  pageSize: 100,
  page: 1,
  data: DownloadBaseListMock,
};
