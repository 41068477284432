import { DDCClickAndCollectDayOfWeek, DDCDaysOfWeek } from './DDC';

export interface DDCsEditDataDaysOfWeek {
  day: DDCDaysOfWeek;
  expirationDay: number | string | null;
  expirationAbsoluteTime: string | null;
}

export type DDCDaysOfWeekData = Partial<Record<DDCDaysOfWeek, DDCsEditDataDaysOfWeek>>;
export type DDCsEditDataClickAndCollectDaysOfWeekData = Record<
  DDCDaysOfWeek,
  DDCClickAndCollectDayOfWeek
>;

export interface clickAndCollectParametersEditData {
  range: number;
  enabled: boolean;
  expirationTime: string;
}
export interface clickAndCollectConsumeEditData {
  fullOrderCapacityActive: boolean;
  fullOrderCapacityValue: number;
  daysOfWeek: DDCsEditDataClickAndCollectDaysOfWeekData;
}

export type clickAndCollectEditData = clickAndCollectParametersEditData &
  clickAndCollectConsumeEditData;

export interface DDCsEditData {
  name?: string | null;
  federatedUnit?: number;
  cityCode?: number;
  beesId?: string;
  singleDDC: boolean;
  timeZoneId: string | null;
  generateDeliveryWindow: boolean | null;
  deliveryDaysAfterVisitDay?: number[];
  deliveryDaysOfWeek?: Array<DDCDaysOfWeek>;
  daysOfWeekSettings?: DDCDaysOfWeekData;
  holidayDeliveryDay?: number | null;
  fieldState: DDCsEditFieldState;
  windowRange?: number;
  clickAndCollectParameters?: clickAndCollectParametersEditData;
  clickAndCollectConsume?: clickAndCollectConsumeEditData;
  deliveryWorkingDays?: Array<DDCDaysOfWeek>;
  locationFieldsEnabled?: boolean;
}

interface DDCsEditDataClickAndCollect {
  clickAndCollectRange?: number;
  clickAndCollectActive?: boolean;
  clickAndCollectDaysOfWeek?: DDCClickAndCollectDayOfWeek[];
}
export interface DDCsEditSubmitData
  extends Omit<DDCsEditData, 'daysOfWeekSettings' | 'clickAndCollect'>,
    DDCsEditDataClickAndCollect {
  daysOfWeekSettings?: DDCsEditDataDaysOfWeek[];
}
export type DDCsEditDataDaysOfWeekAll = Omit<DDCsEditDataDaysOfWeek, 'day'>;

export type ControlFields = 'fieldState' | 'singleDDC' | 'locationFieldsEnabled';

export type DDCsEditField = keyof Omit<DDCsEditData, ControlFields>;
export type DDCsEditFieldState = Partial<Record<DDCsEditField, boolean>>;

export const DDCsEditClickAndCollectParametersFieldStateGroup = [
  'clickAndCollectRange',
  'clickAndCollectActive',
];

export const DDCsEditClickAndCollectDaysOfWeekFieldStateGroup = ['clickAndCollectDaysOfWeek'];

export enum DDCsEditExceptionErrors {
  CncActiveDaysRequired = 'CncActiveDaysRequired',
  InvalidDayOfWeekSequence = 'InvalidDayOfWeekSequence',
  InvalidExpirationTime = 'InvalidExpirationTime',
  InvalidFullOrderCapacityActive = 'InvalidFullOrderCapacityActive',
  InvalidFullOrderCapacityValue = 'InvalidFullOrderCapacityValue',
  ErrorGetDdcBeesInformation = 'ErrorGetDdcBeesInformation',
  OtherError = 'OtherError',
}
export interface DDCsEditResponseData {
  exceptionDdcs: DDCsEditExceptionErrors[];
  notFoundDdcs: string[];
  updatedDdcs: string[];
}

export interface DDCsEditFormStatus {
  originalData: DDCsEditData;
  formDifference: DDCsEditData;
}
