import { DDCHolidayType } from 'domains/DDCHoliday';

export const HolidayListMock = [
  {
    id: 'a516bc27-ce51-455f-ab7a-ac23a47d6433',
    lastUpdateDate: '2021-09-29T18:50:32.409872',
    ddcCode: '357',
    ddcId: '357',
    ddcName: 'Rio de Janeiro / São Cristóvão',
    holidayDate: '2021-09-30T00:00:00',
    cityCode: 999,
    type: DDCHolidayType.local,
    workedHoliday: true,
  },
  {
    id: '1746fd43-1fd6-4e1e-ac18-43c6ef6259ba',
    lastUpdateDate: '2021-10-04T17:12:32.321855',
    ddcCode: '444',
    ddcId: '444',
    ddcName: 'Rio de Janeiro / Nova Iguaçu',
    holidayDate: '2021-10-12T00:00:00',
    cityCode: 999,
    type: DDCHolidayType.state,
    workedHoliday: false,
  },
  {
    id: '1746fd43-1fd6-4e1e-ac18-43c6e31259ba',
    lastUpdateDate: '2021-10-04T17:12:32.321855',
    ddcCode: '1527',
    ddcId: '1527',
    ddcName: 'Espírito Santo / Vitória',
    holidayDate: '2021-10-12T00:00:00',
    cityCode: 999,
    type: DDCHolidayType.national,
    workedHoliday: true,
  },
  {
    id: '1746fd43-1fd6-4e1e-ac18-43c6e31259ba',
    lastUpdateDate: '2021-10-04T17:12:32.321855',
    ddcCode: '1527',
    ddcId: '1527',
    ddcName: 'Espírito Santo / Vitória',
    holidayDate: '2023-11-12T00:00:00',
    cityCode: 999,
    type: DDCHolidayType.national,
    workedHoliday: true,
  },
];

export const LocalHolidayMock = HolidayListMock[0];
export const StateHolidayMock = HolidayListMock[1];
export const NationalHolidayMock = HolidayListMock[2];

export const NewHolidayMock = {
  id: '',
  lastUpdateDate: '2021-10-04T17:12:32.321855',
  ddcCode: '1527',
  ddcId: '1527',
  ddcName: 'Espírito Santo / Vitória',
  holidayDate: '2021-10-12T00:00:00',
  cityCode: 999,
  workedHoliday: true,
  type: DDCHolidayType.local,
};

export const sendNewAndEdittedHolidaysResponseMock = {
  addedOrUpdatedHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d64331',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2022-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  earlierHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d64331',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2021-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  alreadyExistingHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d6433',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2021-09-30T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  missingFederatedUnit: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d6433',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2021-09-30T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
};

export const sendNewAndEdittedHolidaysClearResponseMock = {
  addedOrUpdatedHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d64331',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2022-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  earlierHolidays: [],
  alreadyExistingHolidays: [],
  missingFederatedUnit: [],
};

export const sendDeletedHolidaysResponseMock = {
  deletedHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d6433',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2022-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  earlierHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d64331',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2021-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  notFoundHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d64332',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2021-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
};

export const sendDeletedHolidaysClearResponseMock = {
  deletedHolidays: [
    {
      id: 'a516bc27-ce51-455f-ab7a-ac23a47d6433',
      lastUpdateDate: '2021-09-29T18:50:32.409872',
      ddcCode: '357',
      holidayDate: '2022-01-01T00:00:00',
      cityCode: 999,
      workedHoliday: true,
      type: DDCHolidayType.local,
    },
  ],
  earlierHolidays: [],
  notFoundHolidays: [],
};
