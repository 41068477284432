/* eslint-disable no-useless-escape */
import { permissionRoutes } from 'constants/PermissionRoutes.constant';
import { useLocation } from 'react-router-dom';
import { formatBaseURL } from 'utils/formatBaseURL/formatBaseURL';

export function useRouteScopes(): Array<string> {
  const pathName = formatBaseURL(useLocation().pathname);
  const permissionsPathname = Object.keys(permissionRoutes).find((route) => {
    // eslint-disable-next-line prettier/prettier
    return new RegExp(route.replace(/:\w+/, '(.*?)')).test(pathName);
  });
  return permissionsPathname ? permissionRoutes[permissionsPathname] : ['denied'];
}
