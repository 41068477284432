import { Vendor, useAuthenticationService } from 'admin-portal-shared-services';
import { StorePersistKeys } from 'constants/StorePersistKeys';

export const getSelectedCountry = (): string => {
  const metaData = JSON.parse(localStorage.getItem(StorePersistKeys.metaData) || '{}');

  let metaDataSelectedCountry: string = metaData?.selectedCountry;

  if (!metaDataSelectedCountry) {
    const authentication = useAuthenticationService();
    const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
    const supportedCountries = authentication.getSupportedCountries();

    if (userCountry && supportedCountries.includes(`${userCountry}`)) {
      metaDataSelectedCountry = userCountry;
    } else {
      [metaDataSelectedCountry] = supportedCountries;
    }

    localStorage.setItem(
      StorePersistKeys.metaData,
      JSON.stringify({
        ...metaData,
        selectedCountry: metaDataSelectedCountry,
        selectedVendor: authentication.getVendorId(),
      })
    );
  }

  return metaDataSelectedCountry;
};

export const getSelectedVendor = (fallbackVendorList?: Vendor[]): string => {
  const metaData = JSON.parse(localStorage.getItem(StorePersistKeys.metaData) || '{}');

  let metaDataSelectedVendor: string = metaData?.selectedVendor || '';

  if (!metaDataSelectedVendor && fallbackVendorList?.[0]?.id) {
    metaDataSelectedVendor = fallbackVendorList?.[0].id;
    localStorage.setItem(
      StorePersistKeys.metaData,
      JSON.stringify({
        ...metaData,
        selectedVendor: fallbackVendorList?.[0].id,
        selectedVendorName: fallbackVendorList?.[0].displayName,
      })
    );
  }

  return metaDataSelectedVendor;
};

export const getSelectedVendorName = (fallbackVendorList?: Vendor[]): string => {
  const metaData = JSON.parse(localStorage.getItem(StorePersistKeys.metaData) || '{}');

  let metaDataSelectedVendorName: string = metaData?.selectedVendorName || '';

  if (!metaDataSelectedVendorName && fallbackVendorList?.[0]?.displayName) {
    metaDataSelectedVendorName = fallbackVendorList?.[0]?.displayName;
    localStorage.setItem(
      StorePersistKeys.metaData,
      JSON.stringify({ ...metaData, selectedVendor: fallbackVendorList?.[0]?.displayName })
    );
  }

  return metaDataSelectedVendorName;
};

export const getUserEmail = (): string => {
  const metaData = JSON.parse(localStorage.getItem(StorePersistKeys.metaData) || '{}');

  return metaData?.userEmail || '';
};
