/* eslint-disable @typescript-eslint/no-empty-function */
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { OfficialLanguages } from 'admin-portal-shared-services/dist/services/language/model/LanguageService';
import React, { useMemo } from 'react';
import { updateIntlProvider } from './formatters';
import { DEFAULT_LANGUAGE } from './provider';

interface contextProp {
  language: OfficialLanguages;
  setProviderLanguage?: (value: OfficialLanguages) => void;
}

export const i18nLanguageContext = React.createContext<contextProp>({
  language: '' as OfficialLanguages,
});

interface Props {
  children: React.ReactNode;
}

export const I18nLanguageProvider = ({ children }: Props): JSX.Element => {
  const { preferredLanguage } = usePreferredLanguageV2();

  const abbreviation = useMemo(() => {
    if (preferredLanguage) {
      return preferredLanguage as OfficialLanguages;
    }

    return DEFAULT_LANGUAGE;
  }, [preferredLanguage]);

  const [language, setProviderLanguage] = React.useState<OfficialLanguages>(abbreviation);

  updateIntlProvider(abbreviation);

  return (
    <i18nLanguageContext.Provider value={{ language, setProviderLanguage }}>
      {children}
    </i18nLanguageContext.Provider>
  );
};
