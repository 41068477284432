import { styled } from '@stitches/react';

export const gutters = {
  top: 10,
  bottom: 40,
  left: 24,
  right: 24,
  topBar: 57,
  appBarHeight: 128,
};

export const PageWrapperContainer = styled('div', {
  margin: `${gutters.top}px ${gutters.right}px ${gutters.bottom}px ${gutters.left}px`,
  minHeight: `calc(100vh - ${
    gutters.topBar + gutters.top + gutters.bottom + gutters.appBarHeight
  }px)`,
  display: 'flex',
  flexDirection: 'column',
});

export const SelectContainer = styled('div', {
  position: 'absolute',
  top: '-104px',
  right: '24px',
  display: 'flex',
  '& img': {
    height: '16px',
    width: '21px',
    marginRight: '4px',
    marginTop: '4px',
  },
});

export const SelectWrapper = styled('div', {
  position: 'relative',
});
