/* eslint-disable @typescript-eslint/no-explicit-any */
import { createIntl, createIntlCache, defineMessages, IntlShape } from 'react-intl';
import MessageMap from './domains/MessageMap';
import br from './languages/br';
import en from './languages/en';
import es419 from './languages/es419';

export const DEFAULT_LANGUAGE = 'pt-BR';
const MESSAGES: { [language: string]: MessageMap } = {
  'pt-BR': br,
  'en-US': en,
  'es-419': es419,
};

type FlatMessageMap = Record<string, string>;

const flattenMessages = (nestedMessages: MessageMap): FlatMessageMap =>
  Object.entries(nestedMessages).reduce(
    (messages, [messageKey, message]) =>
      Object.assign(
        messages,
        Object.fromEntries(
          Object.entries(message).map(([key, value]) => [`${messageKey}.${key}`, value])
        )
      ),
    {}
  );

const validateSelectedLanguage = (language: string) => {
  if (!MESSAGES[language]) {
    return DEFAULT_LANGUAGE;
  }
  return language;
};

const defaultMessageMap = flattenMessages(MESSAGES[DEFAULT_LANGUAGE]);

const fillFlattenedMessaged = (messageMap: FlatMessageMap): any => ({
  ...defaultMessageMap,
  ...messageMap,
});

const intlProvider = (locale: string): IntlShape => {
  const cache = createIntlCache();
  const language = validateSelectedLanguage(locale);
  return createIntl(
    {
      locale: language,
      messages: defineMessages(fillFlattenedMessaged(flattenMessages(MESSAGES[language]))),
    },
    cache
  );
};

export default intlProvider;
