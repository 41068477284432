import Client from 'domains/Client';
import DDC from 'domains/DDC';
import { Rule, RuleOwnerType } from 'domains/RulesFormData';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createEvent } from 'effector';

export const resetState = createEvent('resetState');

export const setLoading = createEvent('setLoading');

export const unsetLoading = createEvent('unsetLoading');

export const removeOwnerFromList = createEvent<DDC | Client>('removeOwnerFromList');

export const setOwnerList = createEvent<{
  ownerList: Array<DDC | Client>;
  ownerType: RuleOwnerType;
}>('setOwnerList');

export const clearOwnerList = createEvent('clearOwnerList');

export const queryRulesDone = createEvent<Array<Rule>>('queryRulesDone');

export const queryRulesFail = createEvent<Error>('queryRulesFail');

export const setConfirmModalState = createEvent<boolean>('setConfirmModalState');
