import { createStyles, makeStyles } from '@material-ui/core';

const AppbarConfigProviderStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: '75px',
    },
  })
);

export default AppbarConfigProviderStyles;
