import { useAnalyticsService, useAuthenticationService } from 'admin-portal-shared-services';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { setUserEmail } from 'stores/MetaData/MetaDataEvents';
import MetaDataStore from 'stores/MetaData/MetaDataStore';

export const useSegmentAnalytics = (segmentKey: string): void => {
  useStore(MetaDataStore);
  const analyticsService = useAnalyticsService();
  const jwtDecoded = useAuthenticationService().parseJwt();
  useEffect(() => {
    analyticsService.load(segmentKey);
    analyticsService.identify({ first_name: jwtDecoded?.name, email: jwtDecoded?.email });
    setUserEmail(jwtDecoded?.email || '');
  }, [analyticsService, segmentKey]);
};

export default useSegmentAnalytics;
