import { Server } from 'miragejs';

const logRoutes = (context: Server): void => {
  context.get('/log', () => {
    return { message: 'Information was logged' };
  });

  context.post('/log', () => {
    return { message: 'Information was updated' };
  });

  context.delete('/log', () => {
    return { message: 'Information was deleted' };
  });

  context.put('/log', () => {
    return { message: 'Information was updated' };
  });

  context.patch('/log', () => {
    return { message: 'Information was updated' };
  });
};

export default logRoutes;
