import DDC, { DDCListFilterUpdate } from 'domains/DDC';
import { DDCClickAndCollectModalData } from 'domains/DDCClickAndCollect';
import { DDCHoliday } from 'domains/DDCHoliday';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createEvent } from 'effector';

export const resetState = createEvent<null>('resetState');

export const setLoading = createEvent<null>('setLoading');

export const unsetLoading = createEvent<null>('unsetLoading');

export const clearDDCs = createEvent('clearDDCs');

export const queryDDCsDone = createEvent<{
  ddcsListTotalItems?: number;
  ddcs?: Array<DDC>;
}>('queryDDCsDone');

export const queryDDCsFail = createEvent<Error>('queryDDCsFail');

export const queryHolidaysDone = createEvent<DDCHoliday[]>('queryHolidaysDone');

export const queryHolidaysFail = createEvent<Error>('queryHolidaysFail');

export const updateSelectedList = createEvent<string[]>('updateSelectedList');

export const clearDDCSelectedList = createEvent('clearDDCSelectedList');

export const setDDCAsOnlySelected = createEvent<string>('setDDCAsOnlySelected');

export const removeDDCFromList = createEvent<DDC>('removeDDCFromList');

export const setEditConfirmModalState = createEvent<boolean>('setEditConfirmModalState');

export const setListHolidayConfirmModalState = createEvent<boolean>(
  'setListHolidayConfirmModalState'
);

export const updateDDCListFilter = createEvent<DDCListFilterUpdate>('updateDDCListFilter');

// #region hexa selection

export const hexaSetDDCSelection = createEvent<{ rowId: string; checked: boolean }>(
  'hexaSetDDCSelection'
);

export const hexaSetDDCSelectionAll = createEvent<{ checked: boolean }>('hexaSetDDCSelection');

// #endregion hexa selection

// #region DDC List Click and Collect
export const setDDCClickAndCollectData = createEvent<Partial<DDCClickAndCollectModalData>>(
  'setDDCClickAndCollectData'
);

export const clearDDCClickAndCollectData = createEvent('clearDDCClickAndCollectData');

export const setDDCClickAndCollectModalState = createEvent<boolean>(
  'setDDCClickAndCollectModalState'
);

export const setDDCClickAndCollectModalLoading = createEvent<boolean>(
  'setDDCClickAndCollectModalLoading'
);
// #endregion DDC List Click and Collect
