import { DDCCode } from './DDC';
import { DDCAutoblockStrategy } from './DDCAutoblock';

export interface AutoblockProgress {
  ddcId: string;
  ddcCode: DDCCode;
  ddcName: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  activeStrategyCount: number;
}

export enum AutoblockSituationFilterOptions {
  allGood = 'AllGood',
  warning = 'Warning',
  blocked = 'Blocked',
}

export enum AutoblockProgressSortFields {
  ddcName = 'ddcName',
  lastUpdatedDate = 'lastUpdatedDate',
  activeStrategyCount = 'activeStrategyCount',
}

export interface AutoblockCapacityReachedFilter {
  min: number;
  max: number;
}

export interface AutoblockProgressDetailsStrategy extends DDCAutoblockStrategy {
  activationDate?: string;
}

export interface AutoblockProgressDetails {
  date: string;
  visits: number;
  visitsPercentage: number;
  visitLimit: number;
  createdBy: string;
  strategies: AutoblockProgressDetailsStrategy[];
}
