import { createServer, Server } from 'miragejs';
import factories from './factories';
import models from './models';
import clientRoutes from './routes/clientRoutes';
import ddcsRoutes from './routes/ddcsRoutes';
import deliveryWindowRoutes from './routes/deliveryWindowRoutes';
import holidayRoutes from './routes/holidayRoutes';
import logRoutes from './routes/logRoutes';
import rulesRoutes from './routes/rulesRoutes';
import seeds from './seeds';
import serializers from './serializer';

const startServer = ({ environment = 'development' } = {}): Server => {
  const server: Server = createServer({
    factories,
    models,
    serializers,
    seeds,
    routes() {
      deliveryWindowRoutes(this);
      clientRoutes(this);
      logRoutes(this);
      ddcsRoutes(this);
      holidayRoutes(this);
      rulesRoutes(this);
    },
  });
  server.logging = false;

  return server;
};
export default startServer;
