import { Server } from 'miragejs';
import { RuleDtoListMock, RulesListMock } from '../../../mocks/Rules.mock';
import { RulesResponseSuccessAndErrorDataMock } from '../../../mocks/RulesSaving.mock';

const rulesRoutes = (context: Server): void => {
  context.get('api/flexdelivery-bff/api/deliverywindow/v1/Rule', () => {
    return RulesListMock;
  });
  context.get('api/flexdelivery-bff/api/deliverywindow/v1/RuleInclusion', () => {
    return RuleDtoListMock;
  });
  context.get('api/flexdelivery-bff/api/deliverywindow/v1/RuleExclusion', () => {
    return RuleDtoListMock;
  });

  context.post('api/flexdelivery-bff/api/deliverywindow/v1/Rule/SaveUpdateOrDelete', () => {
    return RulesResponseSuccessAndErrorDataMock;
  });
};

export default rulesRoutes;
