import { useEnvService } from 'admin-portal-shared-services';

export enum Env {
  QA = 'QA',
  DEV = 'DEV',
  E2E = 'E2E',
  UAT = 'UAT',
  SIT = 'QA',
  PROD = 'PROD',
  LOCALHOST = 'LOCALHOST',
}

export const getEnv = (): Env => {
  const currentEnv: Env = useEnvService().getEnv();
  return currentEnv;
};

export const isEnv = (env: Env): boolean => {
  return getEnv() === env;
};

export const isAnyOfEnvs = (envs: Env[]): boolean => {
  return envs.includes(getEnv());
};

export const isLocalHost = (): boolean => {
  return getEnv() === Env.LOCALHOST;
};

export const isDevOrSit = (): boolean => {
  return [Env.LOCALHOST, Env.DEV, Env.SIT].includes(getEnv());
};

export const isNonProd = (): boolean => {
  return getEnv() !== Env.PROD;
};

export const isProd = (): boolean => {
  return getEnv() === Env.PROD;
};
