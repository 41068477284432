import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { ROUTER_BASENAME } from 'config/constants';
import { permissionScopes } from 'constants/PermissionScopes.constant';
import { ROUTES } from 'constants/Routes.constants';
import React, { Suspense, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CheckPermission } from 'utils/checkUserScope/checkUserScope';
import useStyles from './Router.styles';
import {
  AutoblockCreateRoute,
  AutoblockProgressRoute,
  ClientEditRoute,
  ClientRulesRoute,
  DDCsAutoblockRoute,
  DDCsEditRoute,
  DDCsEditV2Route,
  DDCsHolidayRoute,
  DDCsRoute,
  DDCsRulesRoute,
  DeliveryWindowRoute,
  ImportsAndExportsClientDownloadRoute,
  ImportsAndExportsClientUploadRoute,
  ImportsAndExportsRuleDownloadRoute,
  ImportsAndExportsRuleUploadRoute,
  PermissioningRoute,
} from './RouterLazyRoutes';
import LoadingView from './components/loadingView/LoadingView';

const Router = (): JSX.Element => {
  const { containerBody, loadingView, content } = useStyles();

  const userHasImportsExportsClientUploadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ
  );
  const userHasImportsExportsClientDownloadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ
  );
  const userHasImportsExportsRuleUploadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ
  );
  const userHasImportsExportsRuleDownloadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_READ
  );

  const importsExportsRedirect = useMemo(() => {
    if (userHasImportsExportsClientUploadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase`;
    }
    if (userHasImportsExportsClientDownloadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/DownloadClientsBase`;
    }
    if (userHasImportsExportsRuleUploadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase`;
    }
    if (userHasImportsExportsRuleDownloadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/DownloadRulesBase`;
    }
    return ROUTER_BASENAME;
  }, [
    userHasImportsExportsClientUploadPermission,
    userHasImportsExportsClientDownloadPermission,
    userHasImportsExportsRuleUploadPermission,
    userHasImportsExportsRuleDownloadPermission,
  ]);

  const userHasDDCPermission = CheckPermission(permissionScopes.DDC_LIST);

  const userHasClientPermission = CheckPermission(permissionScopes.CLIENT_LIST);

  const homeRedirect = useMemo(() => {
    if (userHasClientPermission) {
      return `${ROUTER_BASENAME}/DeliveryWindow`;
    }
    if (userHasDDCPermission) {
      return `${ROUTER_BASENAME}/DDCs`;
    }
    if (importsExportsRedirect !== ROUTER_BASENAME) {
      return importsExportsRedirect;
    }
    return `/error/404`;
  }, [userHasDDCPermission, userHasClientPermission]);

  return (
    <BrowserRouter>
      <div className={containerBody}>
        <Suspense
          fallback={
            <div className={loadingView}>
              <LoadingView />
            </div>
          }
        >
          <div className={content}>
            <Switch>
              <Route exact path={`${ROUTER_BASENAME}`}>
                <Redirect data-testid="home-base-route" to={homeRedirect} />
              </Route>
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs`}
                render={() => (
                  <PrivateRoute>
                    <DDCsRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs/Edit`}
                render={() => (
                  <PrivateRoute>
                    <DDCsEditV2Route />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs/EditMultiple`}
                render={() => (
                  <PrivateRoute>
                    <DDCsEditRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs/Holiday`}
                render={() => (
                  <PrivateRoute>
                    <DDCsHolidayRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs/DDCsRules`}
                render={() => (
                  <PrivateRoute>
                    <DDCsRulesRoute />
                  </PrivateRoute>
                )}
              />
              <Route exact path={ROUTES.AUTOBLOCK}>
                <Redirect data-testid="autoblock-base-route" to={ROUTES.AUTOBLOCK_PROGRESS} />
              </Route>
              <Route
                exact
                path={ROUTES.AUTOBLOCK_CREATE}
                render={() => (
                  <PrivateRoute>
                    <AutoblockCreateRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={ROUTES.AUTOBLOCK_EDIT}
                render={() => (
                  <PrivateRoute>
                    <AutoblockCreateRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={ROUTES.AUTOBLOCK_LIST}
                render={() => (
                  <PrivateRoute>
                    <DDCsAutoblockRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={ROUTES.AUTOBLOCK_PROGRESS}
                render={() => (
                  <PrivateRoute>
                    <AutoblockProgressRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DeliveryWindow`}
                render={() => (
                  <PrivateRoute>
                    <DeliveryWindowRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DeliveryWindow/ClientEdit`}
                render={() => (
                  <PrivateRoute>
                    <ClientEditRoute />
                  </PrivateRoute>
                )}
              />
              <Route exact path={`${ROUTER_BASENAME}/ImportsAndExports`}>
                <Redirect data-testid="imports-exports-base-route" to={importsExportsRedirect} />
              </Route>
              <Route
                exact
                path={`${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase`}
                render={() => (
                  <PrivateRoute>
                    <ImportsAndExportsClientUploadRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/ImportsAndExports/DownloadClientsBase`}
                render={() => (
                  <PrivateRoute>
                    <ImportsAndExportsClientDownloadRoute />
                  </PrivateRoute>
                )}
              />

              <Route
                exact
                path={`${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase`}
                render={() => (
                  <PrivateRoute>
                    <ImportsAndExportsRuleUploadRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/ImportsAndExports/DownloadRulesBase`}
                render={() => (
                  <PrivateRoute>
                    <ImportsAndExportsRuleDownloadRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DeliveryWindow/ClientRules`}
                render={() => (
                  <PrivateRoute>
                    <ClientRulesRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/Permissioning`}
                render={() => (
                  <PrivateRoute>
                    <PermissioningRoute />
                  </PrivateRoute>
                )}
              />
              <Redirect to={ROUTER_BASENAME} />
            </Switch>
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};
export default Router;
