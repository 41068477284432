import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { Error403 } from '@hexa-ui/components';
import { PageContainer } from 'Global.styles';
import { useUserMetadata } from 'admin-portal-shared-services';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { ROUTER_BASENAME } from 'config/constants';
import { useStore } from 'effector-react';
import { formatMessage } from 'i18n/formatters';
import React from 'react';
import { useHistory } from 'react-router-dom';
import MetaDataStore from 'stores/MetaData/MetaDataStore';

const ErrorNoVendorRoute = (): JSX.Element => {
  const { selectedCountry } = useStore(MetaDataStore);
  const supportedVendors = useUserMetadata().data?.vendors;
  const history = useHistory();
  const hasSupportedVendors = supportedVendors?.some(
    (vendor) => vendor.country === selectedCountry
  );

  React.useEffect(() => {
    if (hasSupportedVendors) {
      history.push(ROUTER_BASENAME);
    }
  }, [selectedCountry, hasSupportedVendors]);

  return (
    <DesignTokensProvider>
      <PageContainer>
        <PageWrapper
          showCountrySelect
          breadcrumbItems={[
            {
              label: formatMessage({ id: 'errorNoVendor.BREADCRUMB' }),
              path: `${ROUTER_BASENAME}`,
              isCurrentPage: true,
            },
          ]}
          pageTitle=""
          optionalButtons={[]}
        >
          <Error403
            description={formatMessage({ id: 'errorNoVendor.DESCRIPTION' })}
            header={formatMessage({ id: 'errorNoVendor.HEADER' })}
            hasButton={false}
          />
        </PageWrapper>
      </PageContainer>
    </DesignTokensProvider>
  );
};

export default ErrorNoVendorRoute;
