import { styled } from '@stitches/react';

export const LoaderContainer = styled('div', {
  width: '100%',
  minHeight: '250px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
