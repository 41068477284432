import { LoadingBuzz } from '@admin-portal-shared-components/spinner';
import React from 'react';
import { LoaderContainer } from './LoadingView.styles';

function LoadingView(): JSX.Element {
  return (
    <LoaderContainer>
      <LoadingBuzz />
    </LoaderContainer>
  );
}

export default LoadingView;
