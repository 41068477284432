export const ClientAccountMock = {
  accountId: '16ecd3cd-1ff5-4383-94f4-efacf95cce7d',
  billingAddress: {
    address: 'Test St. 123 Build. F',
    city: 'Test St. 123 Build. F',
    latitude: 'Test St. 123 Build. F',
    longitude: 'Test St. 123 Build. F',
    state: 'Test St. 123 Build. F',
    zipcode: 'Test St. 123 Build. F',
  },
  challengeIds: ['string'],
  channel: 'On Trade',
  contacts: [
    {
      type: 'PHONE',
      value: '+13458675309',
    },
  ],
  country: 'US',
  createdAt: '2018-11-13T22:08:20.257Z',
  credit: {
    available: 500,
    balance: 1000,
    consumption: 0,
    overdue: 500,
    paymentTerms: '30 DAY CHARGE',
    total: 1000,
    updatedAt: '2018-11-13T22:08:20.257Z',
  },
  customerAccountId: 'US175642359524',
  deliveryAddress: {
    address: 'Test St. 123 Build. F',
    city: 'Los Angeles',
    latitude: '19.454.618',
    longitude: '-7.070.974',
    state: 'California',
    zipcode: '12345-67',
  },
  deliveryCenterId: 'string',
  deliveryRegion: 'T.NORTE',
  deliveryRoute: 'DO0106',
  deliveryScheduleId: '507f191e810c19729de860eb',
  deliveryWindows: [
    {
      alternative: true,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
    {
      alternative: false,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea1',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
    {
      alternative: true,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea2',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
    {
      alternative: true,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea3',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
    {
      alternative: true,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea4',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
    {
      alternative: true,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea5',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
    {
      alternative: true,
      deliveryScheduleId: '507f191e810c19729de860eb',
      deliveryWindowId: '507f191e810c19729de860ea6',
      endDate: '2018-01-31',
      expirationDate: '2018-11-30T09:45:20.257Z',
      startDate: '2018-01-01',
      updatedAt: '2018-11-13T22:08:20.257Z',
      vendorId: '507f191e810c19729de860ea',
    },
  ],
  displayName: 'string',
  erpSalesCenter: 'DOSN',
  hasEmptiesLoan: false,
  hasOverprice: false,
  hasPONumberRequirement: false,
  isKeyAccount: false,
  legalName: 'string',
  liquorLicense: [
    {
      description: 'Beer and Wine',
      expirationDate: '2018-11-13',
      number: '20260375734',
      status: 'VALID',
      type: 'PERMANENT',
    },
  ],
  maximumOrder: {
    paymentMethods: ['CASH'],
    type: 'PRODUCT_QUANTITY',
    value: 20,
  },
  minimumOrder: {
    paymentMethods: ['CASH'],
    type: 'PRODUCT_QUANTITY',
    value: 20,
  },
  name: 'Cooperativa Consumo',
  owner: {
    email: 'carl@la-bar.com',
    firstName: 'Carl',
    lastName: 'Beer',
    phone: '+1 123 45677',
  },
  paymentMethods: ['string'],
  paymentTerms: [
    {
      termPeriods: [
        {
          days: 10,
        },
      ],
      type: 'string',
    },
  ],
  potential: 'P1',
  priceListId: '1',
  representatives: [
    {
      email: 'string',
      name: 'string',
      phone: 'string',
      primary: true,
      type: 'SALES',
    },
  ],
  salesRepresentative: {
    email: 'Mark.Deccard@ab-inbev.com',
    name: 'Mark Deccard',
    phone: '+1 321 54321',
  },
  salesRoute: 'ND',
  segment: 'Transit',
  status: 'ACTIVE',
  subSegment: 'string',
  taxId: 'US10001001001',
  timezone: 'America/Sao_Paulo',
  updatedAt: '2018-11-13T22:08:20.257Z',
  vendorAccountId: 'US10001001001',
  vendorId: '0ef296a1-7e2f-4458-8db7-cf4bf8af1c28',
};
