export enum RuleFileStatus {
  queued = 'awaitingProcessing',
  processing = 'processing',
  processed = 'processed',
  error = 'error',
  invalidFile = 'invalidFile',
}

export enum RuleFileType {
  download = 'DOWNLOAD',
  upload = 'UPLOAD',
}

export enum RuleDownloadFileSubType {
  database = 'Database',
  audit = 'Audit',
}

export interface RuleFile {
  name?: string;
  fileId: string;
  status: RuleFileStatus;
  email: string;
  processingStartDate: string;
  subType?: RuleDownloadFileSubType;
  createdAt: string;
}

export interface RuleUploadFile extends RuleFile {
  errorFileId: string | null;
  name: string;
  endProcessingDate: string | null;
  linesQuantity: number | null;
  successLinesQuantity: number | null;
  errorLinesQuantity: number | null;
}
