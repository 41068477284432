import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { AppHeaderConfigPayload, useAppHeader } from 'admin-portal-shared-services';
import VendorSelect from 'components/VendorSelect/VendorSelect';
import { ROUTER_BASENAME } from 'config/constants';
import React, { useEffect } from 'react';
import CountrySelect from '../CountrySelect/CountrySelect';
import { PageWrapperContainer, SelectContainer, SelectWrapper } from './PageWrapper.styles';

interface BreadcrumbItemProp {
  onClick?: ((...args: unknown[]) => unknown) | undefined;
  path: string;
  label: string;
  isCurrentPage: boolean;
}

interface prop {
  children: React.ReactNode;
  pageTitle: string;
  breadcrumbItems: BreadcrumbItemProp[];
  optionalButtons: AppHeaderConfigPayload['optionalButtons'];
  showCountrySelect?: boolean;
}

export type PageWrapperProps = prop;

function PageWrapper({
  breadcrumbItems,
  pageTitle,
  optionalButtons,
  children,
  showCountrySelect = false,
}: prop): JSX.Element {
  const [, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle,
      breadcrumbConfig: {
        items: breadcrumbItems,
        homePath: ROUTER_BASENAME,
      },
      optionalButtons,
      countrySelect: true,
    });
  }, [optionalButtons]);

  useEffect(() => {
    return () => {
      setAppHeaderConfig({
        pageTitle: '',
        breadcrumbConfig: {
          items: [],
          homePath: ROUTER_BASENAME,
        },
        optionalButtons: [],
        countrySelect: true,
      });
    };
  }, []);

  return (
    <DesignTokensProvider>
      {showCountrySelect && (
        <SelectWrapper>
          <SelectContainer>
            <CountrySelect />
            <VendorSelect />
          </SelectContainer>
        </SelectWrapper>
      )}
      <PageWrapperContainer>{children}</PageWrapperContainer>
    </DesignTokensProvider>
  );
}

export default PageWrapper;
