import { RestSerializer } from 'miragejs';

const serializers = {
  example: RestSerializer.extend({
    keyForCollection() {
      return 'example';
    },
  }),
};

export default serializers;
