import { belongsTo, Model } from 'miragejs';

const models = {
  attribute: Model,
  item: Model,
  example: Model.extend({
    attribute: belongsTo('attribute'),
    exampleTemplate: belongsTo('exampleTemplate', { inverse: 'attributes' }),
  }),
};

export default models;
