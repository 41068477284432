/* eslint-disable react/no-array-index-key */

import { Select, SelectOption } from '@admin-portal-shared-components/select';
import { useUserMetadata } from 'admin-portal-shared-services';
import Flag from 'components/Flag/Flag';
import { useStore } from 'effector-react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { setSelectedCountry } from 'stores/MetaData/MetaDataEvents';
import MetaDataStore from 'stores/MetaData/MetaDataStore';
import { getSelectedCountry } from 'utils/persistMetaData';
import { CountrySelectContainer, CountrySelectWrapper } from './CountrySelect.styles';

const CountrySelect = (): JSX.Element => {
  const { selectedCountry } = useStore(MetaDataStore);

  const history = useHistory();

  const supportedCountries =
    useUserMetadata().data?.supportedCountries?.sort() as unknown as string[];

  const vendorList = useUserMetadata().data?.vendors || [];

  const value = useMemo(() => {
    return getSelectedCountry();
  }, [selectedCountry]);

  const handleChange = React.useCallback(
    (newCountry: string) => {
      setSelectedCountry({
        selectedCountry: newCountry,
        vendorList,
        pathName: history.location.pathname,
      });
    },
    [vendorList, history.location.pathname]
  );

  return (
    <CountrySelectWrapper>
      <CountrySelectContainer>
        <Select
          name="type"
          size="small"
          icon={<Flag country={value} />}
          value={value}
          onChange={handleChange}
          placeholder=""
        >
          {supportedCountries?.map((country) => (
            <SelectOption value={country} key={country}>
              <Flag country={country} />
              {country}
            </SelectOption>
          ))}
        </Select>
      </CountrySelectContainer>
    </CountrySelectWrapper>
  );
};

export default CountrySelect;
