/* eslint-disable react/no-array-index-key */

import React from 'react';
import { FlagIcon } from './Flag.styles';

interface props {
  country: string;
  width?: string;
  height?: string;
}

const Flag = ({ country, width = '21px', height = '16px' }: props): JSX.Element => {
  return <FlagIcon countryCode={country} svg style={{ width, height }} />;
};

export default Flag;
