/* eslint-disable camelcase */
import Client from 'domains/Client';
import DDC from 'domains/DDC';
import { SelectedCountry } from 'tracking';
import {
  getSelectedCountry,
  getSelectedVendor,
  getSelectedVendorName,
  getUserEmail,
} from 'utils/persistMetaData';

interface TrackingClientData {
  multiple: boolean;
  customer_account_id: string[];
  ddc_id: string[];
}
export const formatClientData = (clientList: Client[]): TrackingClientData => {
  const multiple = clientList.length > 1;
  const customerAccountId = clientList.map((client) => client.document);
  const ddcId = clientList.map((client) => client.ddcCode);
  return { multiple, customer_account_id: customerAccountId, ddc_id: ddcId };
};

interface TrackingDDCData {
  multiple: boolean;
  ddc_id: string[];
}
export const formatDDCData = (DDCList: DDC[]): TrackingDDCData => {
  const multiple = DDCList.length > 1;
  const ddcId = DDCList.map((ddc) => ddc.ddcCode);
  return { multiple, ddc_id: ddcId };
};

interface TrackingBasicData {
  selected_country: SelectedCountry;
  screen_name: string;
  vendor_id: string;
  vendor_name: string;
  user_email: string;
}
export const formatTrackingBasicData = (
  screenName: string,
  country?: string
): TrackingBasicData => {
  const selectedCountry = country || getSelectedCountry();
  const selectedVendorId = getSelectedVendor();
  const selectedVendorName = getSelectedVendorName();
  const userEmail = getUserEmail();

  return {
    selected_country: selectedCountry as SelectedCountry,
    screen_name: screenName,
    vendor_id: selectedVendorId,
    vendor_name: selectedVendorName,
    user_email: userEmail,
  };
};
