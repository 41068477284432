/* eslint-disable no-return-await */
import { lazy } from 'react';

type ComponentImportType = () => Promise<{ default: React.ComponentType }>;

const sessionKey = 'lazyWithRetry';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const lazyWithRetry = (componentImport: ComponentImportType) => {
  return lazy(async () => {
    const hasRefreshed = window.sessionStorage.getItem(sessionKey) || 'false';

    try {
      window.sessionStorage.setItem(sessionKey, 'false');
      return await componentImport();
    } catch (error) {
      if (hasRefreshed === 'false') {
        caches?.keys()?.then((names) => {
          names.forEach((name) => {
            caches?.delete(name);
          });
        });
        window.sessionStorage.setItem(sessionKey, 'true');
        window.location.reload();
      }

      if (hasRefreshed === 'true') throw new Error('chunkLoadError');
    }
    return await componentImport();
  });
};

export default lazyWithRetry;

export const DDCsRoute = lazyWithRetry(() => import('./pages/DDCs/DDCsRoute'));
export const DDCsEditRoute = lazyWithRetry(() => import('./pages/DDCsEdit/DDCsEditRoute'));
export const DDCsEditV2Route = lazyWithRetry(() => import('./pages/DDCsEditV2/DDCsEditV2Route'));
export const DDCsAutoblockRoute = lazyWithRetry(
  () => import('./pages/DDCsAutoblock/DDCsAutoblockRoute')
);
export const AutoblockCreateRoute = lazyWithRetry(
  () => import('./pages/AutoblockCreate/AutoblockCreateRoute')
);
export const AutoblockProgressRoute = lazyWithRetry(
  () => import('./pages/AutoblockProgress/AutoblockProgressRoute')
);
export const DDCsHolidayRoute = lazyWithRetry(() => import('./pages/DDCsHoliday/DDCsHolidayRoute'));
export const DDCsRulesRoute = lazyWithRetry(() => import('./pages/DDCsRules/DDCsRulesRoute'));
export const DeliveryWindowRoute = lazyWithRetry(
  () => import('./pages/DeliveryWindow/DeliveryWindowRoute')
);
export const ClientEditRoute = lazyWithRetry(() => import('./pages/ClientEdit/ClientEditRoute'));
export const ImportsAndExportsClientUploadRoute = lazyWithRetry(
  () => import('./pages/ImportsAndExports/Client/Upload/ClientUploadRoute')
);
export const ImportsAndExportsClientDownloadRoute = lazyWithRetry(
  () => import('./pages/ImportsAndExports/Client/Download/ClientDownloadRoute')
);
export const ImportsAndExportsRuleUploadRoute = lazyWithRetry(
  () => import('./pages/ImportsAndExports/Rules/Upload/RuleUploadRoute')
);
export const ImportsAndExportsRuleDownloadRoute = lazyWithRetry(
  () => import('./pages/ImportsAndExports/Rules/Download/RuleDownloadRoute')
);
export const ClientRulesRoute = lazyWithRetry(() => import('./pages/ClientRules/ClientRulesRoute'));
export const PermissioningRoute = lazyWithRetry(
  () => import('./pages/Permissioning/PermissioningRoute')
);
export const RuleCSVUploadRoute = lazyWithRetry(
  () => import('./pages/RuleCSVUpload/RuleCSVUploadRoute')
);
export const ClientCSVUploadRoute = lazyWithRetry(
  () => import('./pages/ClientCSVUpload/ClientCSVUploadRoute')
);
