import { RuleClientType } from 'domains/RulesFormData';
import { RulesSavingResponseData, RulesSavingSubmitData } from 'domains/RulesSaving';

export const RulesFullSubmitDataMock: RulesSavingSubmitData = {
  postRuleInclusion: [
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
      localExpirationDate: '2022-02-02',
      localExpirationTime: '14:59:45;',
      type: RuleClientType.allDdcClients,
      flexible: true,
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
      localExpirationDate: '2022-02-02',
      localExpirationTime: '14:59:45;',
      type: RuleClientType.allDdcClients,
      flexible: true,
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
      localExpirationDate: '2022-02-02',
      localExpirationTime: '14:59:45;',
      type: RuleClientType.allDdcClients,
      flexible: true,
    },
  ],
  updateRuleInclusion: [
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
      localExpirationDate: '2022-02-02',
      localExpirationTime: '14:59:45;',
      type: RuleClientType.allDdcClients,
      flexible: true,
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
      localExpirationDate: '2022-02-02',
      localExpirationTime: '14:59:45;',
      type: RuleClientType.allDdcClients,
      flexible: true,
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
      localExpirationDate: '2022-02-02',
      localExpirationTime: '14:59:45;',
      type: RuleClientType.allDdcClients,
      flexible: true,
    },
  ],
  deleteRuleInclusion: [
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
    },
  ],
  postRuleExclusion: [
    {
      clientDocument: 'string',
      deliveryDate: '2022-02-02T17:39:22.988Z',
      type: RuleClientType.allDdcClients,
    },
    {
      clientDocument: 'string',
      deliveryDate: '2022-02-02T17:39:22.988Z',
      type: RuleClientType.allDdcClients,
    },
  ],
  deleteRuleExclusion: [
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
    },
    {
      clientDocument: '00000000180253',
      deliveryDate: '2022-01-02T14:59:45.674Z',
    },
  ],
};

export const RulesResponseSuccessAndErrorDataMock: RulesSavingResponseData = {
  savedUpdatedOrDeletedRules: [
    {
      clientDocument: '15415415488',
      ruleDate: '2022-04-01T00:00:00Z',
    },
    {
      clientDocument: '00000000180253',
      ruleDate: '2022-04-01T00:00:00Z',
    },
  ],
  errorRules: [
    {
      clientDocument: '15415415480',
      ruleDate: '2022-04-01T00:00:00Z',
      errorFields: ['Timezone'],
    },
    {
      clientDocument: '15415415480',
      ruleDate: '2022-04-02T00:00:00Z',
      errorFields: ['Timezone', 'ExpirationDate'],
    },
  ],
};

export const RulesResponseSuccessDataMock: RulesSavingResponseData = {
  savedUpdatedOrDeletedRules: [
    {
      clientDocument: '00000000180253',
      ruleDate: '2022-04-01T14:59:45.674Z',
    },
    {
      clientDocument: '00000000180253',
      ruleDate: '2022-04-01T14:59:45.674Z',
    },
  ],
  errorRules: [],
};

export const RulesResponseErrorDataMock: RulesSavingResponseData = {
  savedUpdatedOrDeletedRules: [],
  errorRules: [
    {
      clientDocument: '00000000180253',
      ruleDate: '2022-04-01T14:59:45.674Z',
      errorFields: ['Timezone'],
    },
    {
      clientDocument: '00000000180253',
      ruleDate: '2022-04-01T14:59:45.674Z',
      errorFields: ['Timezone', 'ExpirationDate'],
    },
  ],
};

export const RulesResponseEmptyDataMock: RulesSavingResponseData = {
  errorRules: [],
  savedUpdatedOrDeletedRules: [],
};
