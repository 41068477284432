import { useHasPermission } from 'admin-portal-shared-services';
import { permissionScopes } from 'constants/PermissionScopes.constant';

// #region mock to use during implementation and local tests
/* import {
  permissionGroups,
  permissionRoles,
  permissionScopes,
} from 'constants/PermissionScopes.constant';

const useHasPermission = (scopes: string[], strategy: 'AND' | 'OR') => {
  const mockedGroupsToCheck = [
    // ...permissionGroups['LOAT Delivery Window User'],
    // ...permissionGroups.CnCParamsVisualizer,
    ...permissionGroups.CnCParamsOperator,
    // ...permissionGroups.CnCConsumeVisualizer,
    ...permissionGroups.CnCConsumeOperator,
  ];

  const mockedScopesToCheck: string[] = mockedGroupsToCheck.reduce((scopesList, role) => {
    return [...scopesList, ...permissionRoles[role]];
  }, [] as string[]);

  if (strategy === 'OR') {
    return scopes.some((scope) => mockedScopesToCheck.includes(scope));
  }
  return scopes.every((scope) => mockedScopesToCheck.includes(scope));
}; */
// #endregion mock to use during implementation and local tests

/**
 * Check if the user has at least one of the provided scopes
 * - Bypassed by FULL_ACCESS
 * @param userScope list of permissionScopes items
 * @returns boolean
 */
export function CheckOnePermission(userScope: Array<string>): boolean {
  return useHasPermission([...userScope, permissionScopes.FULL_ACCESS], 'OR');
}

/**
 * Check if the user has all of the provided permissions
 * - Bypassed by FULL_ACCESS
 * @param userScope list of permissionScopes items
 * @returns boolean
 */
export function CheckAllPermissions(userScope: Array<string>): boolean {
  return (
    useHasPermission([permissionScopes.FULL_ACCESS], 'AND') || useHasPermission(userScope, 'AND')
  );
}

/**
 * Check if the user has the provided permission
 * - Bypassed by FULL_ACCESS
 * @param userScope single permissionScopes item
 * @returns boolean
 */
export function CheckPermission(userScope: string): boolean {
  return useHasPermission([userScope, permissionScopes.FULL_ACCESS], 'OR');
}
