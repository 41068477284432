import { Response, Server } from 'miragejs';
import {
  APIReturnClientsListMock,
  RemoveClientExceptionFlagResponseDataMock,
  UpdateDaysOfWeekResponseDataMock,
} from '../../../mocks/Client.mock';
import { ClientAccountMock } from '../../../mocks/ClientAccount.mock';
import {
  DownloadBaseListMock,
  DownloadBaseQueuedFileMock,
} from '../../../mocks/ClientDownloadBase.mock';
import { FileMock, UploadHistoryListMock } from '../../../mocks/ClientUpload.mock';

const clientRoutes = (context: Server): void => {
  context.get('api/flexdelivery-bff/api/deliverywindow/v1/Client/GetAll', () => {
    return APIReturnClientsListMock;
  });

  context.post(
    'api/flexdelivery-bff/api/upload/deliverywindow/v1/Client/UploadClientsExceptionsFile',
    () => {
      return { success: true };
    },
    {
      timing: 1000,
    }
  );

  context.post('api/flexdelivery-bff/api/deliverywindow/v1/Client/RequestClientBase', () => {
    return { ...DownloadBaseQueuedFileMock, processingStartDate: new Date().toISOString() };
  });

  context.get(
    'api/flexdelivery-bff/api/deliverywindow/v1/Client/GetAllClientsFileByType',
    (schema, { queryParams }) => {
      if (queryParams.type === 'dowloadClientBase') {
        return DownloadBaseListMock;
      }
      return UploadHistoryListMock;
    }
  );

  context.get('api/flexdelivery-bff/api/deliverywindow/v1/Client/ClientsExceptionsFileUrl', () => {
    console.log("MirageJS can't mock file response");
    return new Response(
      200,
      {
        'Content-Disposition': 'attachment; filename="test.csv"',
        'Content-Type': 'text/csv',
      },
      FileMock
    );
  });

  context.get('api/accounts/', () => {
    return [ClientAccountMock];
  });

  context.post('api/flexdelivery-bff/api/deliverywindow/v1/Client/UpdateDaysOfWeek', () => {
    return UpdateDaysOfWeekResponseDataMock;
  });

  context.post(
    'api/flexdelivery-bff/api/deliverywindow/v1/Client/RemoveClientExceptionFlag',
    () => {
      return RemoveClientExceptionFlagResponseDataMock;
    }
  );
};

export default clientRoutes;
