import {
  ClientFile,
  ClientUploadFile,
  DownloadClientBaseFilter,
  DownloadRulesBaseFilter,
} from 'domains/ClientFile';
import { ImportsAndExportsFilterUpdate } from 'domains/ImportsAndExports';
import PaginationInfo from 'domains/PaginationInfo';
import { RuleFile, RuleUploadFile } from 'domains/RuleFile';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createEvent } from 'effector';

// #region loading
export const resetState = createEvent('resetState');

export const setLoading = createEvent('setLoading');

export const unsetLoading = createEvent('unsetLoading');

export const setDataGridLoading = createEvent('setDataGridLoading');

export const unsetDataGridLoading = createEvent('unsetDataGridLoading');
// #endregion loading

// #region filter

export const updateImportsAndExportsFilter = createEvent<ImportsAndExportsFilterUpdate>(
  'updateImportsAndExportsFilter'
);
// #endregion filter

// #region client upload
export const clearUploadClientHistoryList = createEvent('clearUploadClientHistoryList');

export const queryUploadClientHistoryDone = createEvent<{
  listTotalItems: number;
  uploadClientHistoryList?: ClientUploadFile[];
}>('queryUploadClientHistoryDone');

export const queryUploadClientHistoryFail = createEvent<Error>('queryUploadClientHistoryFail');
// #endregion client upload

// #region client download
export const queryDownloadClientBaseListFail = createEvent<Error>(
  'queryDownloadClientBaseListFail'
);

export const clearDownloadClientBaseList = createEvent('clearDownloadClientBaseList');

export const addItemDownloadClientBaseList = createEvent<{ newFile: ClientFile }>(
  'addItemDownloadClientBaseList'
);

export const queryDownloadClientBaseListDone = createEvent<{
  listTotalItems: number;
  fileList?: ClientFile[];
}>('queryDownloadClientBaseListDone');

export const updateDownloadClientBaseFilter = createEvent<Partial<DownloadClientBaseFilter>>(
  'updateDownloadClientBaseFilter'
);
// #endregion client download

// #region rule upload
export const clearUploadRuleHistoryList = createEvent('clearUploadRuleHistoryList');

export const queryUploadRuleHistoryDone = createEvent<{
  listTotalItems: number;
  uploadRuleHistoryList?: RuleUploadFile[];
}>('queryUploadRuleHistoryDone');

export const queryUploadRuleHistoryFail = createEvent<Error>('queryUploadRuleHistoryFail');
// #endregion rule upload

// #region rule download
export const queryDownloadRuleBaseListFail = createEvent<Error>('queryDownloadRuleBaseListFail');

export const clearDownloadRuleBaseList = createEvent('clearDownloadRuleBaseList');

export const addItemDownloadRuleBaseList = createEvent<{ newFile: RuleFile }>(
  'addItemDownloadRuleBaseList'
);

export const queryDownloadRuleBaseListDone = createEvent<{
  listTotalItems: number;
  fileList?: RuleFile[];
}>('queryDownloadRuleBaseListDone');

export const updateDownloadRulesBaseFilter = createEvent<Partial<DownloadRulesBaseFilter>>(
  'updateDownloadRulesBaseFilter'
);
// #endregion rule download

// #region pagination
export const updatePagination = createEvent<Partial<PaginationInfo>>('updatePagination');
// #endregion pagination

// #region hexa selection

export const hexaSetUploadClientSelection = createEvent<{ rowId: string; checked: boolean }>(
  'hexaSetUploadClientSelection'
);
export const hexaSetUploadClientSelectionAll = createEvent<{ checked: boolean }>(
  'hexaSetUploadClientSelection'
);
export const hexaClearUploadClientSelection = createEvent('hexaClearUploadClientSelection');

export const hexaSetUploadRuleSelection = createEvent<{ rowId: string; checked: boolean }>(
  'hexaSetUploadRuleSelection'
);
export const hexaSetUploadRuleSelectionAll = createEvent<{ checked: boolean }>(
  'hexaSetUploadRuleSelection'
);
export const hexaClearUploadRuleSelection = createEvent('hexaClearUploadRuleSelection');

// #endregion hexa selection
