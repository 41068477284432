import { enUS, es, ptBR } from 'date-fns/locale';
import { FormatDateOptions, FormatNumberOptions, MessageDescriptor } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from './i18n';
import provider, { DEFAULT_LANGUAGE } from './provider';

let intlProvider = provider(DEFAULT_LANGUAGE);

export const updateIntlProvider = (language: string): void => {
  intlProvider = provider(language);
};

export const formatMessage = (
  message: MessageDescriptor,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
): string => {
  return intlProvider.formatMessage(message, values);
};

export const formatDate = (date: number | Date, options?: FormatDateOptions): string =>
  intlProvider.formatDate(date, {
    timeZone: 'UTC',
    ...options,
  });

export const formatNumber = (numberToFormat: number, options?: FormatNumberOptions): string =>
  intlProvider.formatNumber(numberToFormat, options);

export const dateFnsLocale = (): Locale => {
  const locales: Record<string, Locale> = {
    'en-US': enUS,
    'pt-BR': ptBR,
    'es-419': es,
  };
  return locales[intlProvider.locale] ?? ptBR;
};
