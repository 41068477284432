import { styled } from '@stitches/react';

export const CountrySelectContainer = styled('div', {
  /* position: 'absolute',
  width: '110px',
  top: '-104px',
  right: '284px',
  '& img': {
    height: '16px',
    width: '21px',
    marginRight: '4px',
    marginTop: '4px',
  }, */
});

export const CountrySelectWrapper = styled('div', {
  position: 'relative',
});
