import { ROUTER_BASENAME } from 'config/constants';

/**
 * Technical debt: progressively add the routes in here and replace on the entire application
 * Remember: Update PermissounRoutes.constant.ts and events.constants.ts
 */
export const ROUTES = {
  AUTOBLOCK: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters`,
  AUTOBLOCK_LIST: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/List`,
  AUTOBLOCK_PROGRESS: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/Progress`,
  AUTOBLOCK_CREATE: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/Create`,
  AUTOBLOCK_EDIT: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/Edit/:autoblockId`,
  IMPORTS_AND_EXPORTS: `${ROUTER_BASENAME}/ImportsAndExports`,
  IMPORTS_AND_EXPORTS_RULES_UPLOAD: `${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase`,
  IMPORTS_AND_EXPORTS_CLIENTS_UPLOAD: `${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase`,
  RULE_UPLOAD_FILE: `${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase/UploadFile`,
  CLIENT_UPLOAD_FILE: `${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase/UploadFile`,
};

// #region route URL params
export interface AutoblockEditParams {
  autoblockId: string;
}
// #endregion route URL params
