import { Server } from 'miragejs';
import { APIReturnDDCsListMock, CompleteDDCMock, DDCsListMock } from '../../../mocks/DDCs.mock';

const ddcsRoutes = (context: Server): void => {
  context.get('api/flexdelivery-bff/api/deliverywindow/v1/Ddc', () => {
    return CompleteDDCMock;
  });

  context.get('api/flexdelivery-bff/api/deliverywindow/v1/Ddc/GetAll', () => {
    return APIReturnDDCsListMock;
  });

  context.patch('api/flexdelivery-bff/api/deliverywindow/v1/Ddc', () => {
    return DDCsListMock;
  });
};

export default ddcsRoutes;
