import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const mercury = '#E7E7E7';
const alto = '#DDDDDD';
const moonRaker = '#D1C7EF';
const corduroy = '#707372';
const royalBlue = '#4B6EDB';
const lunarGreen = '#373A36';
const silver = '#CCCCCC';
const alizarinCrimson = '#E02B27';
const bombay = '#B9BCC1';
const concrete = '#F3F3F3';
const alabaster = '#FAFAFA';
const gallery = '#EEEEEE';
const darkColor = 900;
const strongColor = 600;
const lightColor = 400;
const navyColor = 800;
const lightGrey = 200;

export default {
  black,
  white,
  mercury,
  alto,
  moonRaker,
  corduroy,
  royalBlue,
  lunarGreen,
  silver,
  alizarinCrimson,
  bombay,
  concrete,
  alabaster,
  primary: {
    contrastText: white,
    dark: colors.grey[darkColor],
    main: black,
    light: colors.indigo[navyColor],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[darkColor],
    main: colors.blue.A400,
    light: colors.blue.A400,
  },
  error: {
    contrastText: white,
    dark: colors.red[darkColor],
    main: colors.red[strongColor],
    light: colors.red[lightColor],
  },
  text: {
    primary: colors.blueGrey[darkColor],
    secondary: colors.blueGrey[strongColor],
    link: colors.blue[strongColor],
    hint: corduroy,
  },
  link: colors.blue[navyColor],
  icon: colors.blueGrey[strongColor],
  background: {
    default: '#F4F6F8',
    paper: white,
    selectedItem: concrete,
    selectedButton: gallery,
  },
  divider: colors.grey[lightGrey],
};
