import { Server } from 'miragejs';

const deliveryWindowRoutes = (context: Server): void => {
  context.get(
    'api/flexdelivery-bff/api/deliverywindow/v1/DeliveryWindowJob',
    () => {
      return undefined;
    },
    {
      timing: 1000,
    }
  );
};

export default deliveryWindowRoutes;
