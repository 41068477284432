import Client, { ClientListFilterUpdate } from 'domains/Client';
import { CompleteDeliveryWindowList } from 'domains/DeliveryWindow';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createEvent } from 'effector';

export const resetState = createEvent<null>('resetState');

export const setLoading = createEvent<null>('setLoading');

export const unsetLoading = createEvent<null>('unsetLoading');

export const setDataGridLoading = createEvent<null>('setDataGridLoading');

export const unsetDataGridLoading = createEvent<null>('unsetDataGridLoading');

export const queryClientListDone = createEvent<{
  clientListTotalItems?: number;
  clientList?: Array<Client>;
}>('queryClientListDone');

export const queryClientListFail = createEvent<Error>('queryClientListFail');

export const updateClientsSelectedList = createEvent<string[]>('updateClientsSelectedList');

export const clearClientselectedList = createEvent('clearClientselectedList');

export const setClientAsOnlySelected = createEvent<string>('setClientAsOnlySelected');

export const removeClientFromList = createEvent<Client>('removeClientFromList');

export const setViewDeliveryWindowsModalState = createEvent<boolean>(
  'setViewDeliveryWindowsModalState'
);

export const setViewDeliveryWindowsModalList = createEvent<Partial<CompleteDeliveryWindowList>>(
  'setViewDeliveryWindowsModalList'
);

export const clearViewDeliveryWindowsModalList = createEvent('clearViewDeliveryWindowsModalList');

export const setClientEditConfirmModalState = createEvent<boolean>(
  'setClientEditConfirmModalState'
);

export const updateClientListFilter = createEvent<ClientListFilterUpdate>('updateClientListFilter');

// #region hexa selection

export const hexaSetClientSelection = createEvent<{ rowId: string; checked: boolean }>(
  'hexaSetClientSelection'
);

export const hexaSetClientSelectionAll = createEvent<{ checked: boolean }>(
  'hexaSetClientSelectionAll'
);

// #endregion hexa selection
