import { Server } from 'miragejs';
import {
  HolidayListMock,
  sendDeletedHolidaysResponseMock,
  sendNewAndEdittedHolidaysResponseMock,
} from '../../../mocks/Holiday.mock';

const ddcsRoutes = (context: Server): void => {
  context.get('api/flexdelivery-bff/api/deliverywindow/v1/Holiday', () => {
    return HolidayListMock;
  });

  context.post('api/flexdelivery-bff/api/holiday/v1/Holiday', () => {
    return sendNewAndEdittedHolidaysResponseMock;
  });

  context.delete('api/flexdelivery-bff/api/holiday/v1/Holiday', () => {
    return sendDeletedHolidaysResponseMock;
  });
};

export default ddcsRoutes;
