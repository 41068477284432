import DDC, { DDCCode } from './DDC';

export enum DDCHolidayType {
  workedHoliday = 'workedHoliday',
  local = 'local',
  state = 'state',
  national = 'national',
}

export enum holidayErrorField {
  earlier = 'earlier',
  alreadyExisting = 'alreadyExisting',
  missingFederatedUnit = 'missingFederatedUnit',
  notFound = 'notFound',
}

export interface DDCHoliday {
  id: string;
  ddcId?: string;
  ddcCode: DDCCode;
  ddcName?: string;
  cityCode: number;
  workedHoliday: boolean;
  holidayDate: string;
  type: DDCHolidayType;
  isEditing?: boolean;
  isDeleting?: boolean;
  isInserting?: boolean;
  errorField?: holidayErrorField;
  federatedUnit?: string;
}

export interface DDCHolidayAddNew {
  holidayDate: string;
  ddcs: DDC[];
}

export interface DDCHolidayForm {
  warningState: boolean;
  holidayList: DDCHoliday[];
}

export interface DDCHolidayDeletePayload {
  ids: string[];
}

export interface DDCHolidayAddEditResponseData {
  addedOrUpdatedHolidays: DDCHoliday[];
  earlierHolidays: DDCHoliday[];
  alreadyExistingHolidays: DDCHoliday[];
  missingFederatedUnit: DDCHoliday[];
}

export interface DDCHolidayDeleteResponseData {
  earlierHolidays: DDCHoliday[];
  deletedHolidays: DDCHoliday[];
  notFoundHolidays: DDCHoliday[];
}

export type DDCHolidayResponseData = {
  addedOrUpdatedHolidays: DDCHoliday[];
  deletedHolidays: DDCHoliday[];
  errorHolidays: DDCHoliday[];
};
