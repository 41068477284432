import { useUserMetadata } from 'admin-portal-shared-services';
import { ROUTER_BASENAME } from 'config/constants';
import { featureToggleItems } from 'config/featureToggleStates';
import { useStore } from 'effector-react';
import { useRouteScopes } from 'hooks/useRouteScopes/useRouteScopes';
import ErrorNoVendorRoute from 'pages/ErrorNoVendor/ErrorNoVendorRoute';
import React from 'react';
import { Redirect } from 'react-router-dom';
import MetaDataStore from 'stores/MetaData/MetaDataStore';
import { CheckOnePermission } from 'utils/checkUserScope/checkUserScope';
import { featureToggle } from 'utils/featureToggle/featureToggle';

const PATHNAME = `${ROUTER_BASENAME}`;

interface Props {
  children: React.ReactNode;
  featureToggleItem?: featureToggleItems;
}

function PrivateRoute({
  children,
  featureToggleItem = featureToggleItems.empty,
}: Props): JSX.Element {
  const scopes = useRouteScopes();
  const hasPermission = CheckOnePermission(scopes);
  const featureToggleEnabled = featureToggle(featureToggleItem);

  const { selectedCountry } = useStore(MetaDataStore);
  const supportedVendors = useUserMetadata().data?.vendors;
  const isLoadingMetadata = useUserMetadata().isLoading;
  const shouldCheckVendor = featureToggle(featureToggleItems.noVendorsErrorScreen);
  const hasSupportedVendors = supportedVendors?.some(
    (vendor) => vendor.country === selectedCountry
  );

  const userHasPermission = React.useMemo(() => {
    if (featureToggleItem) {
      return hasPermission && featureToggleEnabled;
    }
    return hasPermission;
  }, [hasPermission, featureToggleEnabled]);

  const whatToRender = React.useMemo(() => {
    if (shouldCheckVendor && !hasSupportedVendors && !isLoadingMetadata) {
      return <ErrorNoVendorRoute />;
    }

    if (!userHasPermission) {
      return <Redirect to={{ pathname: PATHNAME }} />;
    }

    return children;
  }, [shouldCheckVendor, hasSupportedVendors, isLoadingMetadata, userHasPermission, children]);

  return <>{whatToRender}</>;
}

export default PrivateRoute;
