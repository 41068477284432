import { useSidebar } from 'admin-portal-shared-services';
import React from 'react';
import Router from '../../Router';
import useStyles from './AppbarConfigProvider.styles';
import { getAppbarItems } from './AppbarConfigProviderItems';

const AppBarConfigProvider = (): JSX.Element => {
  const { container } = useStyles();

  useSidebar({
    items: getAppbarItems(),
    utils: [],
  });

  return (
    <div className={container}>
      <Router />
    </div>
  );
};

export default AppBarConfigProvider;
