export enum permissionScopes {
  FULL_ACCESS = 'LOATDeliveryWindow.App.FullAccess',
  DDC_LIST = 'LOATDeliveryWindow.DDCList.Read',
  DDC_PARAMETERS_READ = 'LOATDeliveryWindow.DDCParameters.Read',
  DDC_PARAMETERS_WRITE = 'LOATDeliveryWindow.DDCParameters.Write',
  DDC_REGENERATE_DELIVERY_WINDOWS = 'LOATDeliveryWindow.DDCRegenerateDeliveryWindows.Write',
  DDC_HOLIDAY_READ = 'LOATDeliveryWindow.DDCHoliday.Read',
  DDC_HOLIDAY_WRITE = 'LOATDeliveryWindow.DDCHoliday.Write',
  DDC_RULE_READ = 'LOATDeliveryWindow.DDCRule.Read',
  DDC_RULE_DATE = 'LOATDeliveryWindow.DDCRulesDateValues.Write',
  DDC_RULE_FLEXIBLE = 'LOATDeliveryWindow.DDCRulesFlexibleValues.Write',
  CLIENT_LIST = 'LOATDeliveryWindow.ClientList.Read',
  CLIENT_PARAMETERS_READ = 'LOATDeliveryWindow.ClientParameters.Read',
  CLIENT_REGENERATE_DELIVERY_WINDOWS = 'LOATDeliveryWindow.ClientRegenerateDeliveryWindows.Write',
  CLIENT_PARAMETERS_DATE = 'LOATDeliveryWindow.ClientDateValues.Write',
  CLIENT_PARAMETERS_FREQUENCY = 'LOATDeliveryWindow.ClientCSVUploadFreqValues.Write',
  CLIENT_PARAMETERS_FLEXIBLE = 'LOATDeliveryWindow.ClientFlexibleValues.Write',
  CLIENT_RULE_READ = 'LOATDeliveryWindow.ClientRule.Read',
  CLIENT_RULE_DATE = 'LOATDeliveryWindow.ClientRulesDateValues.Write',
  CLIENT_RULE_FLEXIBLE = 'LOATDeliveryWindow.ClientRulesFlexibleValues.Write',
  CLIENT_DELIVERY_WINDOWS = 'LOATDeliveryWindow.ClientDeliveryWindows.Read',
  IMPORTS_EXPORTS = 'LOATDeliveryWindow.ImportsAndExports.Read',
  IMPORTS_EXPORTS_CLIENT_UPLOAD_READ = 'LOATDeliveryWindow.ClientCSVUpload.Read',
  IMPORTS_EXPORTS_CLIENT_UPLOAD_DATE = 'LOATDeliveryWindow.ClientCSVUploadDateValues.Write',
  IMPORTS_EXPORTS_CLIENT_UPLOAD_FLEXIBLE = 'LOATDeliveryWindow.ClientCSVUploadFlexibleValues.Write',
  IMPORTS_EXPORTS_CLIENT_UPLOAD_CLICKANDCOLLECT = 'LOATDeliveryWindow.ClientCSVUploadClickAndCollectValues.Write',
  IMPORTS_EXPORTS_CLIENT_UPLOAD_FILE_READ = 'LOATDeliveryWindow.ClientCSVUploadFile.Read',
  IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ = 'LOATDeliveryWindow.ClientCSVDownload.Read',
  IMPORTS_EXPORTS_CLIENT_DOWNLOAD_WRITE = 'LOATDeliveryWindow.ClientCSVDownload.Write',
  IMPORTS_EXPORTS_CLIENT_DOWNLOAD_FILE_READ = 'LOATDeliveryWindow.ClientCSVDownloadFile.Read',
  IMPORTS_EXPORTS_RULE_UPLOAD_READ = 'LOATDeliveryWindow.RuleCSVUpload.Read',
  IMPORTS_EXPORTS_RULE_UPLOAD_DATE = 'LOATDeliveryWindow.RuleCSVUploadDateValues.Write',
  IMPORTS_EXPORTS_RULE_UPLOAD_FLEXIBLE = 'LOATDeliveryWindow.RuleCSVUploadFlexibleValues.Write',
  IMPORTS_EXPORTS_RULE_UPLOAD_FILE_READ = 'LOATDeliveryWindow.RuleCSVUploadFile.Read',
  IMPORTS_EXPORTS_RULE_UPLOAD_CLIENT = 'LOATDeliveryWindow.RuleCSVUploadClientRules.Write',
  IMPORTS_EXPORTS_RULE_UPLOAD_CDD = 'LOATDeliveryWindow.RuleCSVUploadDDCRules.Write',
  IMPORTS_EXPORTS_RULE_DOWNLOAD_READ = 'LOATDeliveryWindow.RuleCSVDownload.Read',
  IMPORTS_EXPORTS_RULE_DOWNLOAD_WRITE = 'LOATDeliveryWindow.RuleCSVDownload.Write',
  IMPORTS_EXPORTS_RULE_DOWNLOAD_FILE_READ = 'LOATDeliveryWindow.RuleCSVDownloadFile.Read',
  CLICK_AND_COLLECT_CONSUME_READ = 'LOATDeliveryWindow.ClickAndCollectConsume.Read',
  CLICK_AND_COLLECT_CONSUME_WRITE = 'LOATDeliveryWindow.ClickAndCollectConsume.Write',
  CLICK_AND_COLLECT_PARAMETERS_READ = 'LOATDeliveryWindow.ClickAndCollectParameters.Read',
  CLICK_AND_COLLECT_PARAMETERS_WRITE = 'LOATDeliveryWindow.ClickAndCollectParameters.Write',
  DDC_AUTOBLOCK_READ = 'LOATDeliveryWindow.DDCAutoblock.Read',
  DDC_AUTOBLOCK_WRITE = 'LOATDeliveryWindow.DDCAutoblock.Write',
  INTERNAL_OPERATIONS = 'LOATDeliveryWindow.InternalOperations.Write',
}

export const permissionRoles: Record<string, permissionScopes[]> = {
  Admin: [permissionScopes.FULL_ACCESS],

  DDCHolidayViewer: [permissionScopes.DDC_LIST, permissionScopes.DDC_HOLIDAY_READ],

  DDCHolidayOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.DDC_HOLIDAY_READ,
    permissionScopes.DDC_HOLIDAY_WRITE,
  ],

  DDCRuleViewer: [permissionScopes.DDC_LIST, permissionScopes.DDC_RULE_READ],

  DDCRuleDateOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.DDC_RULE_READ,
    permissionScopes.DDC_RULE_DATE,
  ],

  DDCRuleFlexibleOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.DDC_RULE_READ,
    permissionScopes.DDC_RULE_FLEXIBLE,
  ],

  DDCParametersViewer: [permissionScopes.DDC_LIST, permissionScopes.DDC_PARAMETERS_READ],

  DDCParametersOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.DDC_PARAMETERS_READ,
    permissionScopes.DDC_PARAMETERS_WRITE,
  ],

  DDCParametersDWOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.DDC_PARAMETERS_READ,
    permissionScopes.DDC_REGENERATE_DELIVERY_WINDOWS,
  ],

  ClientDeliveryWindowViewer: [
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLIENT_DELIVERY_WINDOWS,
  ],

  ClientParametersViewer: [permissionScopes.CLIENT_LIST, permissionScopes.CLIENT_PARAMETERS_READ],

  ClientParametersDateOperator: [
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLIENT_PARAMETERS_READ,
    permissionScopes.CLIENT_PARAMETERS_DATE,
    permissionScopes.CLIENT_PARAMETERS_FREQUENCY,
  ],

  ClientParametersFlexibleOperator: [
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLIENT_PARAMETERS_READ,
    permissionScopes.CLIENT_PARAMETERS_FLEXIBLE,
  ],

  ClientParametersDWOperator: [
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLIENT_PARAMETERS_READ,
    permissionScopes.CLIENT_REGENERATE_DELIVERY_WINDOWS,
  ],

  ClientRuleViewer: [permissionScopes.CLIENT_LIST, permissionScopes.CLIENT_RULE_READ],

  ClientRuleDateOperator: [
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLIENT_RULE_READ,
    permissionScopes.CLIENT_RULE_DATE,
  ],

  ClientRuleFlexibleOperator: [
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLIENT_RULE_READ,
    permissionScopes.CLIENT_RULE_FLEXIBLE,
  ],

  ClientCSVUploadViewer: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_FILE_READ,
  ],

  ClientCSVUploadDateOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_DATE,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_FILE_READ,
  ],

  ClientCSVUploadFlexibleOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_FLEXIBLE,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_FILE_READ,
  ],

  ClientCSVDownloadViewer: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_FILE_READ,
  ],

  ClientCSVDownloadOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_WRITE,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_FILE_READ,
  ],

  RuleCSVUploadViewer: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FILE_READ,
  ],

  RuleCSVUploadDDCDateOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_CDD,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_DATE,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FILE_READ,
  ],

  RuleCSVUploadDDCFlexibleOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_CDD,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FLEXIBLE,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FILE_READ,
  ],

  RuleCSVUploadClientDateOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_CLIENT,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_DATE,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FILE_READ,
  ],

  RuleCSVUploadClientFlexibleOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_CLIENT,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FLEXIBLE,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FILE_READ,
  ],

  RuleCSVDownloadViewer: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_FILE_READ,
  ],

  RuleCSVDownloadOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_WRITE,
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_FILE_READ,
  ],

  ClickAndCollectParametersViewer: [
    permissionScopes.DDC_LIST,
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_READ,
  ],

  ClickAndCollectParametersOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.CLIENT_LIST,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_WRITE,
  ],

  ClickAndCollectConsumeOperator: [
    permissionScopes.CLICK_AND_COLLECT_CONSUME_READ,
    permissionScopes.CLICK_AND_COLLECT_CONSUME_WRITE,
    permissionScopes.DDC_LIST,
  ],

  ClickAndCollectConsumeViewer: [
    permissionScopes.CLICK_AND_COLLECT_CONSUME_READ,
    permissionScopes.DDC_LIST,
  ],

  ClickAndCollectCSVParametersOperator: [
    permissionScopes.IMPORTS_EXPORTS,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_CLICKANDCOLLECT,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_FILE_READ,
  ],

  DDCAutoblockViewer: [permissionScopes.DDC_LIST, permissionScopes.DDC_AUTOBLOCK_READ],

  DDCAutoblockOperator: [
    permissionScopes.DDC_LIST,
    permissionScopes.DDC_AUTOBLOCK_READ,
    permissionScopes.DDC_AUTOBLOCK_WRITE,
  ],

  InternalOperator: [permissionScopes.INTERNAL_OPERATIONS],
};

type PermissionRoleName = keyof typeof permissionRoles;

const permissionGroupsHierarchy = {
  'LOAT Delivery Window User': ['Admin'],
  Visualizer: [
    'DDCHolidayViewer',
    'DDCRuleViewer',
    'DDCParametersViewer',
    'ClientDeliveryWindowViewer',
    'ClientParametersViewer',
    'ClientRuleViewer',
    'ClientCSVUploadViewer',
    'ClientCSVDownloadViewer',
    'RuleCSVUploadViewer',
    'RuleCSVDownloadViewer',
  ],
  DDCOperator: [
    'DDCHolidayOperator',
    'DDCParametersOperator',
    'DDCParametersDWOperator',
    'DDCRuleDateOperator',
    'DDCRuleFlexibleOperator',
    'RuleCSVUploadDDCDateOperator',
    'RuleCSVUploadDDCFlexibleOperator',
    'RuleCSVDownloadOperator',
  ],
  ClientOperator: [
    'ClientDeliveryWindowViewer',
    'ClientParametersDateOperator',
    'ClientParametersFlexibleOperator',
    'ClientParametersDWOperator',
    'ClientRuleDateOperator',
    'ClientRuleFlexibleOperator',
    'ClientCSVUploadDateOperator',
    'ClientCSVUploadFlexibleOperator',
    'ClientCSVDownloadOperator',
    'RuleCSVUploadClientDateOperator',
    'RuleCSVUploadClientFlexibleOperator',
    'RuleCSVDownloadOperator',
  ],
  FlexibleOperator: [
    'DDCRuleFlexibleOperator',
    'ClientParametersFlexibleOperator',
    'ClientRuleFlexibleOperator',
    'ClientCSVUploadFlexibleOperator',
    'ClientCSVDownloadOperator',
    'RuleCSVUploadDDCFlexibleOperator',
    'RuleCSVUploadClientFlexibleOperator',
    'RuleCSVDownloadOperator',
  ],

  DateOperator: [
    'DDCHolidayOperator',
    'DDCRuleDateOperator',
    'DDCParametersOperator',
    'DDCParametersDWOperator',
    'ClientDeliveryWindowViewer',
    'ClientParametersDateOperator',
    'ClientParametersDWOperator',
    'ClientRuleDateOperator',
    'ClientCSVUploadDateOperator',
    'ClientCSVDownloadOperator',
    'RuleCSVUploadDDCDateOperator',
    'RuleCSVUploadClientDateOperator',
    'RuleCSVDownloadOperator',
  ],

  HolidayOperator: ['DDCHolidayOperator'],

  ClickAndCollectVisualizer: [
    'ClickAndCollectParametersViewer',
    'ClickAndCollectConsumeViewer',
    'ClientCSVDownloadViewer',
  ],

  ClickAndCollectOperator: [
    'ClickAndCollectParametersOperator',
    'ClickAndCollectConsumeOperator',
    'ClickAndCollectCSVParametersOperator',
    'ClientCSVDownloadOperator',
  ],

  AutoblockViewer: ['DDCAutoblockViewer'],

  AutoblockOperator: ['DDCAutoblockOperator'],

  ClientCSVDownloadOperator: ['ClientCSVDownloadOperator'],

  InternalUser: ['InternalOperator'],

  CnCParamsVisualizer: ['ClickAndCollectParametersViewer'],

  CnCParamsOperator: ['ClickAndCollectParametersOperator', 'ClickAndCollectCSVParametersOperator'],

  CnCConsumeVisualizer: ['ClickAndCollectConsumeViewer'],

  CnCConsumeOperator: ['ClickAndCollectConsumeOperator'],

  Wholesaler: [
    'DDCHolidayViewer',
    'DDCRuleDateOperator',
    'DDCParametersViewer',
    'ClientDeliveryWindowViewer',
    'ClientParametersDateOperator',
    'ClientParametersDWOperator',
    'ClientRuleDateOperator',
    'ClientCSVUploadDateOperator',
    'ClientCSVDownloadOperator',
    'RuleCSVUploadDDCDateOperator',
    'RuleCSVUploadClientDateOperator',
    'RuleCSVDownloadOperator',
  ],
};

type PermissionGroupName = keyof typeof permissionGroupsHierarchy;

export const permissionGroups: Record<PermissionGroupName, PermissionRoleName[]> =
  permissionGroupsHierarchy;
