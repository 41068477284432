import { Server } from 'miragejs';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function seeds(server: Server) {
  //insert your default seed here
}

function createSomething(server: Server) {
  //insert your creator here
}
