import { Factory } from 'miragejs';

const factories = {
  exampleTemplate: Factory.extend({
    name() {
      return 'Fake word';
    },
    description() {
      return 'Fake description';
    },
  }),
};
export default factories;
