import segment from 'tracking';
import { TrackingPageNames } from './events.constants';
import { formatTrackingBasicData } from './eventsUtils';

export const trackCountrySelected = (country: string, pathName: string): void => {
  const screenName =
    Object.keys(TrackingPageNames).find((route) => {
      // eslint-disable-next-line prettier/prettier
      return new RegExp(route.replace(/:\w+/, '(.*?)')).test(pathName);
    }) || '';

  segment.countrySelected(formatTrackingBasicData(TrackingPageNames[screenName] || '', country));
};
